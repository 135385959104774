import { OrderItem } from '@/classes/order/OrderItem';
import { convertPOSPaymentTypeToText } from '@/helpers/converter';
import { dateObjectToStandardAooFormat } from "@/helpers/dateTimeConverter";
import { Device } from './device';

export class POSOrder
{
    orderItems = [];    
    discount = 0;
    discount_type = 'percent';
    delivery_cost = 0;    
    email;
    note;
    payby;

    #id;
    #orderNumber;
    #status;    
    #abbNumber;
    #device;
    #customer;
    
    #updated_at;
    created_at;
    
    #creator;
    #vat;
    #trd;
    
    warehouse;

    get isCancellable() {
        if (this.status == 'cancelled') return false

        return true
    }

    get id() {
        return this.#id;
    }

    get orderNumber() {
        return this.#orderNumber;
    }

    get priceBeforeDiscount() {
        return this.orderItems.reduce((previousValue, currentValue) => previousValue + currentValue.priceSum * 1, 0)
    }

    get discountInValue() {
        if (this.discount_type == 'percent') return (this.discount * this.priceBeforeDiscount / 100).toFixed(2);

        return this.discount;
    }

    get priceAfterDiscount() {
        return this.priceBeforeDiscount - this.discountInValue;
    }

    get VAT() {
        return this.#vat
    }

    get VATSum() {
        const beforeDiscount = this.orderItems.reduce((previousValue, currentValue) => previousValue + currentValue.VATInValue * 1, 0)

        if (this.discount_type == 'percent') return ((100 - this.discount) * beforeDiscount / 100).toFixed(2);

        const percent = this.discount / this.priceBeforeDiscount;

        return (beforeDiscount * (1 - percent)).toFixed(2);
    }

    get priceBeforeVAT() {        

        return this.totalPrice - this.VATSum
    }

    get totalPrice() {
        return this.priceAfterDiscount;
    }

    get status() {
        return this.#status
    }

    get abbNumber() {
        return this.#abbNumber
    }

    get paidAt() {
        return this.createdAt
    }

    get device() {
        return this.#device
    }

    get headerAddress() {
        if (!this.#device) return ''

        return this.#device.fullAddress
    }

    get headerTaxID() {
        return this.#device.taxid
    }

    get tel() {
        if (!this.#device) return ''

        return this.#device.tel
    }

    get branch() {
        if (!this.#device) return ''

        return 'สาขา ' + this.#device.branch
    }

    get updatedAt() {
        if (!this.#updated_at) return '-'

        return dateObjectToStandardAooFormat(new Date(this.#updated_at))
    }

    get createdAt() {
        if (!this.created_at) return '-'

        return dateObjectToStandardAooFormat(new Date(this.created_at))
    }

    get seller() {
        if (!this.#creator) return 'ขายโดยระบบ'

        return this.#creator.name + ' ' + this.#creator.lastname
    }

    get trd() {
        return this.#trd
    }

    get payment() {
        if (!this.payby) return 'เงินสด';        
        
        return this.payby.name + ' (' + convertPOSPaymentTypeToText(this.payby.type) + ')';
        
    }

    get paymentId() {
        return !!this.payby ? this.payby.id : 0
    }

    get customer() {
        return this.#customer
    }

    get customerName() {
        return !!this.#customer ? this.#customer.name : ''
    }

    get customerTel() {
        return !!this.#customer ? this.#customer.tel : ''
    }

    get customerAddress() {
        if (!this.#customer) return ''

        const { address, city, county, province, postcode } = this.#customer

        return address + " " + city + " " + county + " " + province + " " + postcode
    }

    get quantityCount() {
        return this.orderItems.map(item => item.quantity).reduce((prevVal, curVal) => prevVal + curVal, 0)
    }

    constructor({ 
        id = null, 
        orderNumber = null,
        abbNumber = null,                
        note = '',
        status = 'new',         
        discount = 0, 
        discount_type = 'percent',         
        delivery_cost = 0,                 
        updated_at = null,
        created_at = null,        
        creator = null,
        vat = false,
        trd = null,
        products = [],
        payby,
        device,
        customer
    })
    {
        this.#id = id;
        this.#orderNumber = orderNumber;
        this.#status = status;
        this.#abbNumber = abbNumber;                        
        this.#updated_at = updated_at
        this.created_at = created_at
        this.#creator = creator
        this.#vat = vat
        this.#trd = trd
        this.#device = !!device ? new Device(device) : null
        this.#customer = customer

        this.payby = payby
                
        this.note = note;

        this.discount = !!discount ? discount : 0;
        this.discount_type = discount_type;
        
        this.delivery_cost = delivery_cost;        

        products.forEach(product => {
            const { id, pivot, images } = product
            const { name, sku, barCode, note, quantity, price, discount, discount_type, vat, unit } = pivot

            const item = new OrderItem(id, !!images && images.length > 0 ? images[0].URI : null, name, sku, barCode, note, quantity, price, discount, discount_type, vat, unit)

            this.addOrderItem(item)
        })
    }

    addOrderItem(orderItem) {
        if (!(orderItem instanceof OrderItem)) throw "can add only OrderItem Class"
        
        this.orderItems.push(orderItem)
    }    

    serialize() {
        return {
            products: this.orderItems.map(data => {
                return {
                  id: data.id,
                  quantity: data.quantity,
                  discount: data.discount,
                  discount_type: data.discount_type,
                  note: data.note,
                  price: data.price,
                  unit: data.unit,
                }
            }),            
            name: this.name,
            address: this.address,
            city: this.city,
            county: this.county,
            province: this.province,
            postcode: this.postcode,
            tel: this.tel,
            email: this.email,
            note: this.note,            
            discount: this.discount,
            discount_type: this.discount_type,        
            payment_id: this.paymentId    
        }
    }
}