<template>
    <div>
      <v-expansion-panels>
        <v-expansion-panel
        :key="'MANUAL'"
        v-if="options['MANUAL']"
        >
          <v-expansion-panel-header>
            จัดส่งด้วยตัวเอง
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Manual
            :order="order"
            @done="done"
            ></Manual>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="!!options['SHIPPOP']"
          :key="'SHIPPOP'"
        >
          <v-expansion-panel-header>
            ส่งด้วย SHIPPOP
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Shippop
            :order="order"
            :defaultAddress="options['defaultAddress']"
            :courierList="options['SHIPPOP']"
            @done="done"
            ></Shippop>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="isShopeeDropOffAvailable"
          :key="'SHOPEE_DROPOFF'"
        >
          <v-expansion-panel-header>
            ส่งด้วยตัวเอง (Shopee)
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ShopeeDropOff
            :order="order"
            :requiredFields="options['SHOPEE_DROPOFF']"
            :branchList="options['dropoff']['branch_list']"
            :slugList="options['dropoff']['slug_list']"
            @done="done"
            ></ShopeeDropOff>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="isShopeePickupAvailable"
          :key="'SHOPEE_PICKUP'"
        >
          <v-expansion-panel-header>
            เรียกขนส่ง (Shopee)
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ShopeePickup
            :order="order"
            :requiredFields="options['SHOPEE_PICKUP']"
            :addressList="options['pickup']['address_list']"
            @done="done"
            ></ShopeePickup>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="isShopeeNonAvailable"
          :key="'SHOPEE_NON'"
        >
          <v-expansion-panel-header>
            ส่งด้วยตัวเอง (ไม่เชื่อมต่อกับ Shopee)
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ShopeeNon
            :order="order"
            :requiredFields="options['SHOPEE_NON']"
            @done="done"
            ></ShopeeNon>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
</template>
<script>
import Manual from './Manual.vue'
import Shippop from './Shippop.vue'
import ShopeeDropOff from './shopee/ShopeeDropOff.vue'
import ShopeeNon from './shopee/ShopeeNon.vue'
import ShopeePickup from './shopee/ShopeePickup.vue'
export default {
  props: ['order', 'options'],
  data() {
    return {}
  },
  computed: {
    isShopeeDropOffAvailable() {
      return Array.isArray(this.options['SHOPEE_DROPOFF']) && this.options['SHOPEE_DROPOFF'].length > 0
    },
    isShopeePickupAvailable() {
      return Array.isArray(this.options['SHOPEE_PICKUP']) && this.options['SHOPEE_PICKUP'].length > 0
    },
    isShopeeNonAvailable() {
      return Array.isArray(this.options['SHOPEE_NON']) && this.options['SHOPEE_NON'].length > 0
    },
  },
  methods: {
    done() {
      this.$emit('done')
    },
  },
  components: {
    Manual,
    Shippop,
    ShopeeDropOff,
    ShopeePickup,
    ShopeeNon,
  },
}
</script>
