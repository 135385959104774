import facebookSVG from '@/assets/images/3rdparty/facebook.svg'
import instagramSVG from '@/assets/images/3rdparty/instagram.svg'
import lineOASVG from '@/assets/images/3rdparty/lineoa.svg'
import shopeeSVG from '@/assets/images/3rdparty/shopee.svg'
import wooSVG from '@/assets/images/3rdparty/woo.svg'

export const convertFromServerToVueFileAgent = (dataArray) => {
    if (!!!dataArray) return Array.isArray(dataArray) ? [] : null    

    return dataArray.map(data => {
        const urlFragments = data.URI.split(".")                
        const fileExtension = urlFragments[urlFragments.length - 1]
        const fileName = data.hash
        
        return {
            name: fileName + '.' + fileExtension,
            ext: fileExtension,
            type: data.type,
            size: data.size,
            url: data.URI,            
            upload: {
                data: data,
                error: false
            }
        }
    })            
}

export const convertFromVueFileAgentToServer = (dataArray) => {
    if (!!!dataArray) return Array.isArray(dataArray) ? [] : null

    if (!Array.isArray(dataArray)) return dataArray.upload.data.id

    return dataArray.map(data => data.upload.data.id)     
}

export const convertDelayAutoSelectValueToServer = (dataArray) => {    
    if (!!!dataArray) return Array.isArray(dataArray) ? [] : null

    if (!Array.isArray(dataArray)) return dataArray.id

    return dataArray.map(data => data.id)
}

export const convertDataFromServerToDelayAutoSelect = (dataArray) => {    
    if (!!!dataArray) return Array.isArray(dataArray) ? [] : null

    if (!Array.isArray(dataArray)) return { id: dataArray.id, name: dataArray.name }

    return dataArray.map(data => {
        return { id: data.id, name: data.name }
    })
}

export const convertFromServerToDelayAutoSelect = (dataArray) => {
    if (!!!dataArray) return Array.isArray(dataArray) ? [] : null

    if (!Array.isArray(dataArray)) return { id: dataArray.id, name: dataArray.name}

    return dataArray.map(data => { 
        return {
            name: data.name,
            id: data.id,
        }
    })
}

export const convertVariationFromServerToForm = (attributeFormArray, dataArray) => {
    if (!!!dataArray) return Array.isArray(dataArray) ? [] : null        

    return dataArray.map(data => { 
        const image = !!data.images ? convertFromServerToVueFileAgent(data.images)[0] : null
        const index = JSON.parse(data.term_index)
        const options = JSON.parse(data.options)
        /*
        const options = []

        if (index.length == 1) {
            options.push({
                'attribute': attributeFormArray[0].name,
                'term': attributeFormArray[0].terms[index[0]].name
            })
        } else {
            index.forEach((element, attKey) => {
                options.push({
                    'attribute': attributeFormArray[attKey].name,
                    'term': attributeFormArray[attKey].terms[element].name
                })
            });
        }
        */

        return {            
            ...data,
            index: index,
            image: image,
            options: options
        }
    })
}

export const convertAttributesAndTermsFromServerToForm = (AttributeArray, TermArray) => {
    if (!!!AttributeArray) return Array.isArray(AttributeArray) ? [] : null

    const newAttributeArray = [];

    AttributeArray.forEach(element => {
        newAttributeArray.push({...element, terms: []})
    });

    TermArray.forEach(element => {
        const findAttribute = newAttributeArray.find(felement => element.pivot.attribute_id == felement.id)

        if (!findAttribute) return;

        findAttribute.terms.push(element)
    });

    return newAttributeArray
}

export const convertInventoryDataFromServerToForm = (convertedVariationData) => {
    return convertedVariationData.map(data => {
        const warehouses = data.inventory.map(inventoryData => {
            return {
                id: inventoryData.warehouse_id,
                quantity: inventoryData.quantity - inventoryData.reserved
            }
        })
        return {
            ...data,
            warehouses: warehouses
        }
    })
}

export const convertTimestampToAooDefault = (timestamp) => {
    const dateObject = new Date(timestamp)

    return dateObject.getDate().toString().padStart(2, '0')
    + '/' 
    + (dateObject.getMonth() * 1 + 1).toString().padStart(2, '0')
    + '/' + dateObject.getFullYear() 
    + ' ' 
    + dateObject.getHours().toString().padStart(2, '0') 
    + ':' 
    + dateObject.getMinutes().toString().padStart(2, '0') 
    + ':' 
    + dateObject.getSeconds().toString().padStart(2, '0')
}

export const convertDateTomeToAooDefault = (dateObject) => {
    return dateObject.getDate().toString().padStart(2, '0')
    + '/' 
    + (dateObject.getMonth() * 1 + 1).toString().padStart(2, '0')
    + '/' + dateObject.getFullYear() 
    + ' ' 
    + dateObject.getHours().toString().padStart(2, '0') 
    + ':' 
    + dateObject.getMinutes().toString().padStart(2, '0') 
    + ':' 
    + dateObject.getSeconds().toString().padStart(2, '0')
}

export const convertTypeToString = (type) => {
    switch (type) {
        case 'sold':
            return 'ขาย'
            break;
        case 'add':
            return 'รับของเข้า'
            break;
        case 'cancelled':
            return 'ยกเลิกรับของ'
            break;
        case 'refunded':
            return 'บิลยกเลิก'
            break;
        case 'moved_in':
            return 'โอนย้ายเข้า'
            break;
        case 'moved_out':
            return 'โอนย้ายออก'
            break;
        case 'edited':
            return 'แก้ไขสต๊อก'
            break;
        case 'edited_negative':
            return 'แก้ไขสต๊อก'
            break;
        case 'returned':
            return 'ส่งคืนซัพฯ'
            break;
        case 'received':
            return 'รับแล้ว'
            break;
        case 'sold':
            return 'ขายแล้ว'
            break;   
        default:
            return 'ไม่ทราบ'
            break;
    }
}

export const isTypePositive = (type) => {
    switch (type) {
        case 'sold':
            return false
            break;
        case 'add':
            return true
            break;
        case 'cancelled':
            return false
            break;
        case 'refunded':
            return true
            break;
        case 'moved_in':
            return true
            break;
        case 'moved_out':
            return false
            break;
        case 'edited':
            return true
            break;
        case 'edited_negative':
            return false
            break;
        case 'returned':
            return false
            break;
        default:
            return true
            break;
    }
}

export const convertOrderStatusToColor = (status) => {
    switch (status) {
        case 'draft':
            return '#5B6573'
            break;
        case 'new':
            return '#F3CC63'
            break;
        case 'pending_info':
            return '#E8776E'
            break;
        case 'pending_confirm':
            return '#E8776E'
            break;
        case 'pending_delivery':
            return '#5930C9'
            break;
        case 'dispatched_cod':
            return '#6E9FFB'
            break;
        case 'dispatched':
            return '#6CCCA3'
            break;
        case 'received':
            return '#6CCCA3'
            break;
        case 'sold':
            return '#6CCCA3'
            break;   
        case 'returned':
            return '#6CCCA3'
            break;   
        default:
            return '#5B6573'
            break;
    }
}

export const convertOrderStatusToText = (status) => {
    switch (status) {
        case 'draft':
            return 'แบบร่าง'
            break;
        case 'new':
            return 'ใหม่'
            break;
        case 'pending_info':
            return 'รอที่อยู่'
            break;
        case 'pending_confirm':
            return 'รอยืนยัน'
            break;
        case 'pending_delivery':
            return 'รอจัดส่ง'
            break;
        case 'dispatched_cod':
            return 'ส่งแล้ว'
            break;
        case 'dispatched':
            return 'ส่งแล้ว'
            break;
        case 'cancelled':
            return 'ยกเลิก'
            break;
        case 'expired':
            return 'หมดอายุ'
            break;
        case 'received':
            return 'รับแล้ว'
            break;
        case 'sold':
            return 'ขายแล้ว'
            break; 
        case 'returned':
            return 'คืนของแล้ว'
            break;   
        default:
            return 'ไม่ทราบ'
            break;
    }
}

export const valueOrEmptyString = (value) => {
    return !!value ? value : ''
}

export const convertChannelTypeToText = (type) => {
    switch (type) {
        case null:
            return 'ทั่วไป'
            break;
        case 'sc_fb':
            return 'Facebook'
            break;
        case 'sc_ig':
            return 'Instagram'
            break;
        case 'line':
            return 'Line OA'
            break;
        case 'woo':
            return 'Woocommerce'
            break;
        default:
            return type
            break;
    }
}

export const convertChannelTypeToImage = (type) => {
    switch (type) {                
        case 'sc_fb':
            return facebookSVG
            break;        
        case 'sc_ig':
            return instagramSVG
            break;        
        case 'line':
            return lineOASVG
            break;
        case 'woo':
            return wooSVG
            break;
        case 'shopee':
            return shopeeSVG
            break;            
        default:
            return null
            break;
    }
}

export const convertPageToText = (text) => {
    switch (text) {
        case 'Dashboard':
            return 'แดชบอร์ด';
            break;
        case 'OnlineOrder':
            return 'รายการขายออนไลน์';
            break;
        case 'PosOrder':
            return 'รายการขาย POS';
            break;
        case 'BuyOrder':
            return 'จัดซื้อ';
            break;
        case 'Product':
            return 'สินค้า';
            break;
        case 'Inventory':
            return 'สต๊อก/คลัง';
            break;
        case 'Customer':
            return 'ลูกค้า';
            break;
        case 'Report':
            return 'รายงาน';
            break;
        case 'Document':
            return 'เอกสาร';
            break;
        case 'Employee':
            return 'พนักงาน';
            break;
        case 'Setting':
            return 'ตั้งค่า';
            break;
        default:
            return ''
            break;
    }
}

export const convertPermissionToText = (permission) => {
    switch (permission) {
        case 'read':
            return 'อ่าน'
            break;
        case 'update':
            return 'สร้าง/แก้ไข'
            break;
        case 'delete':
            return 'ลบ/ยกเลิก'
            break;
        case 'cost':
            return 'เห็นทุน'
            break;
        default:
            return ''
            break;
    }
}

export const convertPOSPaymentTypeToText = (type) => {
    switch (type) {
        case 'credit_full':
            return 'เครเดิต เต็ม'
            break;
        case 'credit_installment':
            return 'เครดิต ผ่อน'
            break;
        case 'transfer':
            return 'โอน'
            break;        
        default:
            return ''
            break;
    }
}

export const convertCourierCodeToText = (courierCode) => {
    switch (courierCode) {
        case 'manual':
            return 'อื่นๆ ทั่วไป';
            break;
        case 'EMST':
            return 'ไปรษณีย์ไทย';
            break;
        case 'DHL':
            return 'DHL';
            break;
        case 'FLE':
            return 'Flash Express';
            break;
        case 'KRYX':
            return 'Kerry';
            break;
        default:
            return courierCode;
            break;
    }
}

export const convertDocumentStatusToText = (status) => {
    switch (status) {
        case null:
            return 'ยังไม่ได้ขอ'
            break;
        case 'READY':
            return 'พร้อม'
            break;
        case 'PROCESSING':
            return 'กำลังประมวลผล'
            break;
        case 'FAILED':
            return 'ล้มเหลว'
            break;
        default:
            return status
            break;
    }
}

export const jsonToBase64 = (object) => {
    if (!object) return ''
    
    const json = JSON.stringify(object);
    return Buffer.from(json).toString("base64");
}

export const base64ToJson = (base64String) => {
    const json = Buffer.from(base64String, "base64").toString();
    return JSON.parse(json);
}

/*
Vue.filter('cutText', function (text, length) {
    if (text && text.length > length)
      return text.slice(0, length).toString() + '...';
    return text;
  });
  
  Vue.filter('formatNumber', function (number, tofixed = 2) {
    if (!Number.isNaN(number) && Number.isFinite(number))
      return number.toFixed(tofixed).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return 'ไม่สามารถประมวลผลได้';
  });
*/