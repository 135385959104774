import { asyncGet } from '@/helpers/asyncAxios';
import VueCookies from 'vue-cookies';
export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    globalLoading: false,
    commonDialog: {
      isShowDialog: false,
      title: '',
      body: ''
    },
    confirmDialog: {
      isShowDialog: false,
      title: '',
      body: '',
      buttons: null,
      result: null
    },
    selectedCompany: {},
    appMode: 'create',
    print: {
      type: null,
      data: null,
      company: null,
      is_printed: false
    },
    isMuted: false
  },
  getters: {
    GET_SELECTED_COMPANY_ID(state) {
      return state.selectedCompany ? state.selectedCompany.id : null
    },
    GET_COMPANY_NAME(state) {
      return state.selectedCompany ? state.selectedCompany.name: '-'
    },
    GET_COMPANY_IMAGE(state) {
      return state.selectedCompany.profile
    },
    GET_COMPANY_OWNERSHIP(state) {
      return state.selectedCompany.owner ? 'เจ้าของกิจการ' : 'พนักงาน'
    },
    GET_IS_OWNER(state) {
      return state.selectedCompany.owner
    },
    GET_COMPANY_PACKAGE(state) {
      return state.selectedCompany.package ? state.selectedCompany.package : 'แพ็คเกจใหม่'
    },
    GET_APP_MODE(state) {
      return state.appMode
    },
    GET_CHAT_UNREAD(state) {
      return state.chat.unread
    },
    GET_OWNER_ID(state) {
      return state.selectedCompany.owner_id
    },
    GET_PRINT_TYPE(state) {
      return state.print.type
    },
    GET_PRINT_DATA(state) {
      return state.print.data
    },
    GET_PRINT_COMPANY(state) {
      return state.print.company
    },
    GET_PRINT_FLAG(state) {
      return state.print.is_printed
    },
    GET_COVER_PRINT_SIZE(state) {
      if (!state.print.company) return 'A4';

      if (state.print.company.print_cover_size == "paperang") return 'A6';

      if (state.print.company.print_cover_size == "sticker") return 'A6';

      return state.print.company.print_cover_size;
    },
    GET_COVER_PRINT_PRODUCT(state) {
      return state.print.company ? state.print.company.print_cover_product : false
    },
    GET_MUTED_STATE(state) {
      return state.isMuted
    },
    GET_GLOBAL_LOADING(state) {
      return state.globalLoading
    }
  },
  mutations: {
    TOGGLE_MUTED_STATE(state) {
      state.isMuted = !state.isMuted

      VueCookies.set('muted_state', state.isMuted ? "true" : "false")
    },
    SET_MUTED_STATE(state, value) {
      state.isMuted = value
    },
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    SET_GLOBAL_LOADING(state, value) {
      state.globalLoading = value;
    },
    SET_COMPANY_PROFILE(state, payload) {
      state.selectedCompany = payload;
    },
    SHOW_COMMON_DIALOG(state, {title, body}) {
      state.commonDialog.title = title
      state.commonDialog.body = body
      state.commonDialog.isShowDialog = true
    },
    SHOW_CONFIRM_DIALOG(state, {title, body, buttons = null}) {
      state.confirmDialog.title = title
      state.confirmDialog.body = body
      state.confirmDialog.isShowDialog = true
      state.confirmDialog.result = null

      if (!!buttons)
        state.confirmDialog.buttons = buttons
      else
        state.confirmDialog.buttons = [
          {
            text: "ยกเลิก",
            color: "secondary",
            result: false
          },
          {
            text: "ตกลง",
            color: "primary",
            result: true
          }
        ]
    },
    SET_CONFIRM_DIALOG_RESULT(state, result) {
      state.confirmDialog.isShowDialog = false
      state.confirmDialog.result = result
    },
    CLEAR_SELECTED_COMPANY(state) {
      state.selectedCompany = {}
    },
    SET_APP_MODE(state, mode) {
      state.appMode = mode
    },
    SET_PRINT_DATA(state, { type, data, company}) {
      state.print = {
        type: type,
        data: data,
        company: company,
        is_printed: false
      }
    },
    SET_PRINT_FLAG(state) {
      state.print.is_printed = true
    },
    CLEAR_PRINT_DATA(state) {
      state.print = {
        type: null,
        data: null,
        company: null,
        is_printed: false
      }
    },
  },
  actions: {
    SWITCH_COMPANY_PROFILE({ commit, state }, companyId) {
      return new Promise( async (resolve, reject) => {
        if (companyId === state.selectedCompany.id) resolve(true)

        try {
          const response = await asyncGet('/company/' + companyId)

          const { company } = response;
          resolve(commit('SET_COMPANY_PROFILE', company));
        } catch (error) {

          reject(commit('SHOW_COMMON_DIALOG', { title: 'มีปัญหา' , body: error}))
        }
      })
    },
    CONFIRM_DIALOG({ commit, state }, {title, body, buttons = null}) {
      return new Promise( async (resolve) => {
        commit('SHOW_CONFIRM_DIALOG', { title, body, buttons })

        const int = setInterval(() => {
          if (state.confirmDialog.result !== null) {
            clearInterval(int)
            resolve(state.confirmDialog.result)
          }
        }, 50)
      })
    },
  },
}
