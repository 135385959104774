<template>
    <v-form>
        <v-row>
            <v-col>
                <v-text-field
                    v-model="formData.tracking_number"
                    label="หมายเลขจัดส่ง"
                    outlined
                    dense
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn
                class="primary"
                :loading="isLoading"
                @click="submitData"
                >ส่งแล้ว</v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import { asyncPost } from '@/helpers/asyncAxios'
import errorParser from '@/plugins/errorparser'

export default {
  props: ['order'],
  data() {
    return {
      isLoading: false,
      formData: {
        tracking_number: '',
      },
    }
  },
  methods: {
    async submitData() {
      this.isLoading = true

      try {
        await asyncPost('/saleorder/pendingdelivery/' + this.order.id + '/shopee/non', this.formData)

        this.$emit('done')
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }

      this.isLoading = false
    },
  },
}
</script>
