<template>
    <div>
        <h2>รายการขาย</h2>
        <order-status-bar
          :labelGroups="labelGroups"
          v-model="datatable.selectedStatus"
        />
        <v-card class="mt-4 px-6 pt-6">
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                outlined
                v-model="datatable.search"
                placeholder="ค้นหา ..."
                dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <delay-autocomplete
                  outlined
                  hide-selected
                  deletable-chips
                  multiple
                  chips
                  cache-items
                  v-model="datatable.salechannel_id"
                  label="ช่องทางการขาย"
                  placeholder="พิมพ์เพื่อค้นหาช่องทางการขาย"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/saleorder/salechannelList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="salechannelAutocomplete"
                  item-text="name"
                  item-value="id"
                ></delay-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox
                  label="ดูเฉพาะสินค้า preorder"
                  v-model="datatable.only_preorder"
                  class="mt-0"
                ></v-checkbox>
              </v-col>
            </v-row>
        </v-card>
        <v-card class="mt-4">
          <div class="d-flex pa-4">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="datatable.selectedItems.length == 0"
                  icon
                ><v-icon>{{ mdiPrinter }}</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="printMultiple($PRINT_TYPE.ORDER)"
                >
                  <v-list-item-title>ใบสั่งของ</v-list-item-title>
                </v-list-item>

                <!--
                <v-list-item
                  @click="printMultiple($PRINT_TYPE.PICK_LIST)"
                >
                  <v-list-item-title>ใบจัดของ</v-list-item-title>
                </v-list-item>

                <v-list-item
                  @click="printMultiple($PRINT_TYPE.COVER)"
                >
                  <v-list-item-title>ใบปะหน้า</v-list-item-title>
                </v-list-item>
                -->

                <v-list-item
                  @click="printMultiple($PRINT_TYPE.ABBA4)"
                >
                  <v-list-item-title>ใบกำกับภาษีอย่างย่อ A4</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
            class="ml-2"
            @click="exportExcel"
            :disabled="datatable.selectedItems.length == 0"
            icon
            ><v-icon>{{ mdiFileExcel }}</v-icon></v-btn>
            <v-btn color="primary" class="ml-auto" @click="$router.push({ 'name': 'saleorder_create', params: { saleorder_id: 'create' } })">สร้างรายการขาย</v-btn>
          </div>
          <delay-datatable
            show-select
            :headers="datatable.headers"
            :url="'/saleorder/list'"
            :queryParams="getDatatableQueryParams"
            :options.sync="datatable.options"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
            class="fixed-column-table"
            :mobile-breakpoint="0"
          >

          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox
              :value="props.value || props.indeterminate"
              v-on="on"
              :indeterminate="props.indeterminate"
              color="primary"
              :ripple="false"
            />
          </template>

          <template
            v-slot:item.created_at="{ item }"
          >
            {{ item.created_at | timestampToText }}
          </template>

          <template
            v-slot:item.creator="{ item }"
          >
            {{ item.creator ? item.creator.name + " " + item.creator.lastname : 'ระบบ' }}
          </template>

          <template
            v-slot:item.updated_at="{ item }"
          >
            {{ item.updated_at | timestampToText }}
          </template>

          <template
            v-slot:item.paid_at="{ item }"
          >
            <div v-if="item.paid_at">
            {{ item.paid_at | timestampToText }}
            </div>
            <div v-else>
              -
            </div>
          </template>

          <template
            v-slot:item.updater="{ item }"
          >
            {{ item.updater ? item.updater.name + " " + item.updater.lastname : '-' }}
          </template>

          <template
            v-slot:item.trd="{ item }"
          >
            <router-link
            v-if="item.trd"
            :to="{ name: 'trd_create', params: { trd_id: item.trd.id } }"
            >{{ item.trd.trdNumber }}</router-link>
          </template>

          <template
            v-slot:item.slipImg="{ item }"
          >
            <v-img
            :src="item.slipImg"
            max-width="100"
            @click="showLightBox(item.id)"
            ></v-img>
            <LightBox
              :media="[
                {
                  thumb: item.slipImg,
                  src: item.slipImg
                }
              ]"
              :showLightBox="false"
              siteLoading="ไม่พบรูปภาพ"
              :ref='"lightbox_" + item.id'
            ></LightBox>
          </template>

          <template
            v-slot:item.orderNumber="{ item }"
          >
            <router-link
            :to="{ name: 'saleorder_create', params: { saleorder_id: item.id } }"
            >{{ item.orderNumber }}</router-link>
          </template>

          <template
            v-slot:item.salechannel="{ item }"
          >
            {{ !!item.salechannel ? item.salechannel.name : '-' }}
          </template>

          <template
            v-slot:item.payby="{ item }"
          >
            {{ !!item.payby ? item.payby.name : '-' }}
          </template>

          <template
            v-slot:item.value="{ item }"
          >
            {{ item.value | formatNumber(item.value, 2) }}
          </template>

          <template
            v-slot:item.customer="{ item }"
          >
            <div>{{ item.sns_name }}</div>
            <template v-if="!!item.customer">
              <div>{{ item.customer.name }}</div>
              <div>{{ item.customer.tel }}</div>
            </template>
          </template>

          <template
            v-slot:item.address="{ item }"
          >
          {{ item.address }} {{ item.county }} {{ item.city }} {{ item.province }} {{ item.postcode }}
          </template>

          <template
            v-slot:item.status="{ item }"
          >
            <v-chip-group>
              <v-chip
                :color="item.status | orderStatusToColor"
                label
              >
                {{ item.status | orderStatusToText }}
              </v-chip>
            </v-chip-group>

          </template>

          <template
            v-slot:item.additional_status="{ item }"
          >
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                  :color="item.is_print ? 'success' : 'black'"
                  @click="togglePrint(item.id, item.is_print)"
                  v-bind="attrs"
                  v-on="on"
                  >{{ mdiPrinter }}</v-icon>
                </template>
                <span class="text-h6 font-weight-regular">{{ item.is_print ? 'พิมพ์ใบจัดของแล้ว' : 'ยังไม่ได้พิมพ์ใบจัดของ' }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                  :color="item.is_packed ? 'success' : 'black'"
                  class="ml-2"
                  @click="togglePack(item.id, item.is_packed)"
                  v-bind="attrs"
                  v-on="on"
                  >{{ mdiPackageVariantClosed }}</v-icon>
                </template>
                <span class="text-h6 font-weight-regular">{{ item.is_packed ? 'จัดของแล้ว' : 'ยังไม่ได้จัดของ' }}</span>
              </v-tooltip>
              <!--
              <v-tooltip top
                v-if="item.delivery_method.courier_code != 'manual' && item.status == 'pending_delivery'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                  :color="item.delivery_booking_id ? 'success' : 'black'"
                  class="ml-2"
                  @click="toggleDeliveryBooking(item.id, item.delivery_booking_id)"
                  v-bind="attrs"
                  v-on="on"
                  >{{ mdiTruckDeliveryOutline }}</v-icon>
                </template>
                <span class="text-h6 font-weight-regular">{{ item.delivery_booking_id ? 'เรียกขนส่งแล้ว' : 'ยังไม่ได้เรียกขนส่ง' }}</span>
              </v-tooltip>
              -->
            </div>
          </template>

          <template
            v-slot:item.trackingNo="{ item }"
          >
            {{ item.delivery_package ? item.delivery_package.tracking_no : '' }}
          </template>

          <template
            v-slot:item.note="{ item }"
          >
            <EditableTextArea
              :value="item.note"
              :url="'saleorder/' + item.id + '/note'"
              :fieldName="'note'"
            />
          </template>

          <template
            v-slot:item.actions="{ item }"
          >
            <td class="fixed-column">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                  >
                    <v-icon>{{ mdiMenu }}</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="!isThirdPartyOrder(item)
                  && item.payment_type == 'cod'
                  && item.status != 'draft'
                  && item.status != 'cancelled'
                  && !item.paid_at"
                  @click="markPaid(item.id, item.orderNumber)">
                    <v-list-item-icon>
                      <v-icon>{{mdiCashCheck}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ชำระแล้ว</v-list-item-title>
                  </v-list-item>

                  <v-list-item v-if="item.status != 'draft' && item.status != 'cancelled'" @click="showLinkBox(item.id, item.viewToken, item.orderNumber)">
                    <v-list-item-icon>
                      <v-icon>{{mdiLinkVariant}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ขอลิ้ง</v-list-item-title>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item v-if="item.payment_type != 'cod' && !!item.paid_at && !!item.customer" @click="printSingle($PRINT_TYPE.ORDER, item.id)">
                    <v-list-item-icon>
                      <v-icon>{{mdiPrinter}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ใบสั่งของ</v-list-item-title>
                  </v-list-item>

                  <!--
                  <v-list-item @click="printSingle($PRINT_TYPE.PICK_LIST, item.id)">
                    <v-list-item-icon>
                      <v-icon>{{mdiPrinter}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ใบจัดของ</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="printSingle($PRINT_TYPE.COVER, item.id)" v-if="item.is_cod || !!item.paid_at">
                    <v-list-item-icon>
                      <v-icon>{{mdiPrinter}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ใบปะหน้า {{ item.delivery_method.courier_code != 'manual' ? '/ เรียกขนส่ง': '' }} </v-list-item-title>
                  </v-list-item>
                  -->

                  <template v-if="!!item.abbNumber && !item.is_cod && !!item.paid_at && !!item.customer">
                    <v-divider></v-divider>

                    <v-list-item @click="printSingle($PRINT_TYPE.ABBA4, item.id)">
                      <v-list-item-icon>
                        <v-icon>{{mdiPrinter}}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="ml-2">{{ !!item.vat ? 'ใบกำกับภาษีอย่างย่อ A4' : 'ใบเสร็จ/ใบส่งของ'}}</v-list-item-title>
                    </v-list-item>
                  </template>

                  <template v-else-if="item.is_cod && !!item.customer">
                    <v-divider></v-divider>

                    <!--
                    <v-list-item @click="printSingle($PRINT_TYPE.INV, item.id)">
                      <v-list-item-icon>
                        <v-icon>{{mdiPrinter}}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="ml-2">พิมพ์ใบส่งของ COD</v-list-item-title>
                    </v-list-item>
                    -->

                    <v-list-item
                    v-if="!!item.paid_at"
                    @click="printSingle($PRINT_TYPE.RCPT, item.id)">
                      <v-list-item-icon>
                        <v-icon>{{mdiPrinter}}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="ml-2">พิมพ์ใบเสร็จ COD</v-list-item-title>
                    </v-list-item>
                  </template>

                  <template v-if="!!item.abbNumber && !!item.paid_at">
                    <v-divider></v-divider>
                    <v-list-item
                      v-if="!!item.trd"
                      @click="$router.push({ name: 'trd', query: { trdId: item.trd.id } })">
                      <v-list-item-icon>
                        <v-icon>{{mdiPrinter}}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="ml-2">ใบกำกับภาษีแบบเต็ม</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                    v-else
                    @click="$router.push({ name: 'trd_create', params: { trd_id: 'create' }, query: { order_id: item.id, orderNumber: item.orderNumber, order_type: 'saleorder' } })">
                      <v-list-item-icon>
                        <v-icon>{{mdiPrinter}}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="ml-2">ออกใบกำกับภาษีแบบเต็ม</v-list-item-title>
                    </v-list-item>
                  </template>

                  <template v-if="$store.getters['auth/GET_ABILITY'].can('update', 'OnlineOrder')">

                  <v-divider></v-divider>

                  <v-list-item v-if="!isThirdPartyOrder(item) && item.status == 'new'" @click="$router.push({ name: 'saleorder_manual', params: { saleorder_id: item.id }})">
                    <v-list-item-icon>
                      <v-icon>
                        {{ mdiCloudUpload }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">อัพโหลดหลักฐานการชำระ</v-list-item-title>
                  </v-list-item>

                  <v-list-item v-if="item.status == 'pending_info'" @click="$router.push({ name: 'saleorder_info', params: { saleorder_id: item.id }})">
                    <v-list-item-icon>
                      <v-icon>
                        {{ mdiInformationOutline }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ใส่ข้อมูลจัดส่ง</v-list-item-title>
                  </v-list-item>

                  <!--
                  <v-list-item v-if="item.status == 'pending_delivery'" @click="pack(item.id, item.orderNumber)">
                    <v-list-item-icon>
                      <v-icon>
                        {{ mdiPackageVariantClosed }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">จัดของแล้ว</v-list-item-title>
                  </v-list-item>

                  <v-list-item v-if="!isThirdPartyOrder(item) && item.status == 'pending_delivery'" @click="dispatchOrder(item.id, item.orderNumber)">
                    <v-list-item-icon>
                      <v-icon>
                        {{ mdiTruckDeliveryOutline }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">จัดส่งแล้ว</v-list-item-title>
                  </v-list-item>
                  -->

                  <v-list-item v-if="item.status == 'pending_confirm'" @click="$router.push({ name: 'saleorder_confirm', params: { saleorder_id: item.id }})">
                    <v-list-item-icon>
                      <v-icon>
                        {{ mdiCheck }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ยืนยันรายการ</v-list-item-title>
                  </v-list-item>

                  </template>

                  <v-divider v-if="item.status != 'draft'"></v-divider>

                  <v-list-item @click="$router.push({name: 'saleorder_create', params: { saleorder_id: item.id }})">
                    <v-list-item-icon>
                      <v-icon>
                        {{ !isThirdPartyOrder(item) && $store.getters['auth/GET_ABILITY'].can('update', 'OnlineOrder') ? mdiPlaylistEdit : mdiEyeOutline }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ดู / แก้ไข</v-list-item-title>
                  </v-list-item>

                  <template v-if="$store.getters['auth/GET_ABILITY'].can('update', 'OnlineOrder')">
                    <v-divider></v-divider>

                    <v-list-item @click="$router.push({name: 'saleorder_create', params: { saleorder_id: 'create' },query: { duplicate: item.id }})">
                      <v-list-item-icon>
                        <v-icon>
                        {{ mdiContentCopy }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="ml-2">คัดลอก</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="!isThirdPartyOrder(item)" @click="cancelOrder(item.id, item.orderNumber)">
                      <v-list-item-icon>
                        <v-icon>
                        {{ mdiDelete }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="ml-2">ยกเลิก</v-list-item-title>
                    </v-list-item>
                  </template>

                </v-list>
              </v-menu>
            </td>
          </template>
          </delay-datatable>
        </v-card>
        <v-dialog v-model="linkReqDialog.isShow" max-width="600">
          <v-card>
            <v-card-title>ลิ้งของบิล #{{ linkReqDialog.orderNumber }}</v-card-title>
            <div class="px-4">
              <copy-box
                dense
                outlined
                :value="linkReqDialog.link"
              ></copy-box>
            </div>
            <v-card-actions class="justify-end">
              <v-btn @click="linkReqDialog.isShow = false" color="primary">ปิด</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { Order } from '@/classes/order/Order'
import EditableTextArea from '@/components/EditableTextArea.vue'
import cover from '@/components/print/sendcover.vue'
import ProductImage from '@/components/ProductImage.vue'
import { asyncDelete, asyncGet, asyncPost } from '@/helpers/asyncAxios'
import {
  mdiCashCheck,
  mdiCheck,
  mdiCloudUpload,
  mdiContentCopy,
  mdiDelete,
  mdiEyeOutline,
  mdiFileExcel,
  mdiInformationOutline,
  mdiLinkVariant,
  mdiMenu,
  mdiPackageVariantClosed,
  mdiPlaylistEdit,
  mdiPrinter,
  mdiTruckDeliveryOutline,
} from '@mdi/js'
import LightBox from 'vue-image-lightbox'
import * as XLSX from 'xlsx'
import OrderStatusBar from './components/OrderStatusBar.vue'
export default {
  data() {
    return {
      linkReqDialog: {
        isShow: false,
        link: null,
        orderNumber: null,
      },
      labelGroups: [
        [
          {
            id: 'all',
            text: 'ทั้งหมด',
            counter: 0,
            color: '#D64935',
            textColor: 'white',
          },
        ],
        [
          {
            id: 'draft',
            text: 'แบบร่าง',
            counter: 0,
            color: '#5B6573',
            textColor: 'white',
          },
        ],
        [
          {
            id: 'new',
            text: 'ใหม่',
            counter: 0,
            color: '#F3CC63',
            textColor: 'white',
          },
        ],
        [
          {
            id: 'pending_info',
            text: 'รอที่อยู่',
            counter: 0,
            color: '#E8776E',
            textColor: 'white',
          },
          {
            id: 'pending_confirm',
            text: 'รอยืนยัน',
            counter: 0,
            color: '#E8776E',
            textColor: 'white',
          },
        ],
        [
          {
            id: 'pending_delivery',
            text: 'รอจัดส่ง',
            counter: 0,
            color: '#5930C9',
            textColor: 'white',
          },
        ],
        [
          {
            id: 'dispatched_cod',
            text: 'ส่งแล้ว',
            subtext: 'ยังไม่ได้เงิน',
            counter: 0,
            color: '#6E9FFB',
            textColor: 'white',
          },
        ],
        [
          {
            id: 'dispatched',
            text: 'ส่งแล้ว',
            subtext: 'ได้เงินแล้ว',
            counter: 0,
            color: '#6CCCA3',
            textColor: 'white',
          },
        ],
        [
          {
            id: 'cancelled',
            text: 'ยกเลิก',
            counter: 0,
            color: '#5B6573',
            textColor: 'white',
          },
          {
            id: 'expired',
            text: 'หมดอายุ',
            counter: 0,
            color: '#5B6573',
            textColor: 'white',
          },
        ],
      ],
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['created_at'],
          sortDesc: [true],
        },
        selectedStatus: 'all',
        selectedItems: [],
        salechannel_id: null,
        search: null,
        only_preorder: false,
        headers: [
          {
            text: 'เลขที่บิล',
            value: 'orderNumber',
          },
          {
            text: 'วันที่สร้าง',
            value: 'created_at',
          },
          {
            text: 'ผู้สร้าง',
            value: 'creator',
            sortable: false,
            width: '200',
          },
          {
            text: 'วันที่แก้ไข',
            value: 'updated_at',
          },
          {
            text: 'ผู้แก้ไข',
            value: 'updater',
            sortable: false,
          },
          {
            text: 'ช่องทางการขาย',
            value: 'salechannel',
            sortable: false,
            width: '200',
          },
          {
            text: 'ลูกค้า',
            value: 'customer',
            sortable: false,
            width: '200',
          },
          {
            text: 'ที่อยู่จัดส่ง',
            value: 'address',
            width: '200',
          },
          {
            text: 'มูลค่ารวม',
            value: 'value',
            width: '125',
          },
          {
            text: 'สถานะ',
            value: 'status',
          },
          {
            text: 'สถานะอื่นๆ',
            value: 'additional_status',
            sortable: false,
            width: '150',
          },
          {
            text: 'วันที่ชำระ',
            value: 'paid_at',
          },
          {
            text: 'ช่องทางการชำระ',
            value: 'payby',
            sortable: false,
            width: '200',
          },
          {
            text: 'หลักฐานการชำระ',
            value: 'slipImg',
            sortable: false,
          },
          {
            text: 'หมายเหตุ',
            value: 'note',
            width: '200',
          },
          {
            text: 'หมายเหตุลูกค้า',
            value: 'customer_note',
            width: '200',
          },
          {
            text: 'เลขพัสดุ',
            value: 'trackingNo',
            sortable: false,
            width: 200,
          },
          {
            text: 'ใบกำกับภาษีแบบเต็ม',
            value: 'trd',
            sortable: false,
            width: '200',
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'fixed-column',
          },
        ],
      },
      mdiEyeOutline,
      mdiPlaylistEdit,
      mdiMenu,
      mdiLinkVariant,
      mdiCloudUpload,
      mdiInformationOutline,
      mdiTruckDeliveryOutline,
      mdiCheck,
      mdiDelete,
      mdiPrinter,
      mdiCashCheck,
      mdiPackageVariantClosed,
      mdiContentCopy,
      mdiFileExcel,
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        salechannel_id: this.datatable.salechannel_id,
        selectedStatus: this.datatable.selectedStatus,
        search: this.datatable.search,
        only_preorder: this.datatable.only_preorder,
      }
    },
  },
  created() {
    this.queryCount()
  },
  mounted() {
    if (!this.$route.query.orderNumber || !this.$route.query.orderId || !this.$route.query.viewToken) return

    this.showLinkBox(this.$route.query.orderId, this.$route.query.viewToken, this.$route.query.orderNumber)
  },
  methods: {
    isThirdPartyOrder(order) {
      if (order.salechannel.type == 'shopee') return true

      return false
    },
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    isArray(data) {
      return Array.isArray(data)
    },
    async queryCount() {
      try {
        const {
          total,
          draft,
          new_,
          pending_info,
          pending_confirm,
          pending_delivery,
          dispatched,
          dispatched_cod,
          cancelled,
          expired,
        } = await asyncGet('/saleorder/count')

        this.labelGroups = [
          [
            {
              id: 'all',
              text: 'ทั้งหมด',
              counter: total,
              color: '#D64935',
              textColor: 'white',
            },
          ],
          [
            {
              id: 'draft',
              text: 'แบบร่าง',
              counter: draft,
              color: '#5B6573',
              textColor: 'white',
            },
          ],
          [
            {
              id: 'new',
              text: 'ใหม่',
              counter: new_,
              color: '#F3CC63',
              textColor: 'white',
            },
          ],
          [
            {
              id: 'pending_info',
              text: 'รอที่อยู่',
              counter: pending_info,
              color: '#E8776E',
              textColor: 'white',
            },
            {
              id: 'pending_confirm',
              text: 'รอยืนยัน',
              counter: pending_confirm,
              color: '#E8776E',
              textColor: 'white',
            },
          ],
          [
            {
              id: 'pending_delivery',
              text: 'รอจัดส่ง',
              counter: pending_delivery,
              color: '#5930C9',
              textColor: 'white',
            },
          ],
          [
            {
              id: 'dispatched_cod',
              text: 'ส่งแล้ว',
              subtext: 'ยังไม่ได้เงิน',
              counter: dispatched_cod,
              color: '#6E9FFB',
              textColor: 'white',
            },
          ],
          [
            {
              id: 'dispatched',
              text: 'ส่งแล้ว',
              subtext: 'ได้เงินแล้ว',
              counter: dispatched,
              color: '#6CCCA3',
              textColor: 'white',
            },
          ],
          [
            {
              id: 'cancelled',
              text: 'ยกเลิก',
              counter: cancelled,
              color: '#5B6573',
              textColor: 'white',
            },
            {
              id: 'expired',
              text: 'หมดอายุ',
              counter: expired,
              color: '#5B6573',
              textColor: 'white',
            },
          ],
        ]
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    exportExcel() {
      const headers = this.datatable.headers.filter(item => item.value != 'actions').map(header => header.value)
      const headersText = this.datatable.headers.filter(item => item.value != 'actions').map(header => header.text)

      const data = this.datatable.selectedItems.map(item => {
        return headers.map(header => {
          if (!!item[header]) {
            if (header == 'created_at' || header == 'updated_at' || header == 'paid_at')
              return this.$options.filters.timestampToText(item[header])

            if (header == 'status') return this.$options.filters.orderStatusToText(item[header])

            if (header == 'creator' || header == 'updater') return item[header].name + ' ' + item[header].lastname

            if (header == 'customer') return item['sns_name'] + '\n\r' + item[header].name + '\n\r' + item[header].tel

            if (header == 'address')
              return item.address + ' ' + item.county + ' ' + item.city + ' ' + item.province + ' ' + item.postcode

            if (!!item[header].name) return item[header].name

            return item[header]
          }

          if (item[header] == 0) return 0

          return ''
        })
      })

      const arrayData = [headersText, ...data]

      const dataWS = XLSX.utils.aoa_to_sheet(arrayData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, 'export_saleorders.xlsx')
    },
    showLinkBox(orderId, viewToken, orderNumber) {
      this.linkReqDialog.orderNumber = orderNumber
      this.linkReqDialog.link = process.env.VUE_APP_BILL_URL + '/' + orderId + '/view/' + viewToken
      this.linkReqDialog.isShow = true
    },
    async markPaid(id, orderNumber) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'จ่ายเงินแล้ว ?',
        body:
          'บิลหมายเลข "' +
          orderNumber +
          '" ได้รับการจ่ายเงินแล้วใช่หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถแก้ไขสถานะกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncPost('/saleorder/' + id + '/markPaid', {})
        this.$refs.mainDatatable.reload()
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    async bookingDelivery(id, orderNumber) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'จัดส่งแล้ว ?',
        body:
          'บิลหมายเลข "' +
          orderNumber +
          '" ได้รับการจัดส่งแล้วใช่หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถแก้ไขสถานะกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncPost('/saleorder/' + id + '/dispatch', {})
        this.$refs.mainDatatable.reload()
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    async dispatchOrder(id, orderNumber) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'จัดส่งแล้ว ?',
        body:
          'บิลหมายเลข "' +
          orderNumber +
          '" ได้รับการจัดส่งแล้วใช่หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถแก้ไขสถานะกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncPost('/saleorder/' + id + '/dispatch', {})
        this.$refs.mainDatatable.reload()
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    async cancelOrder(id, orderNumber) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยกเลิก ?',
        body:
          'คุณต้องการยกเลิกบิลหมายเลข "' +
          orderNumber +
          '" ใช่หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถแก้ไขสถานะกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      try {
        await asyncDelete('/saleorder/delete', {
          items: [id],
        })

        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    async printMultiple($type) {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)

      const itemIds = this.datatable.selectedItems.map(item => item.id)

      try {
        const { company, data } = await asyncGet('/saleorder/' + $type, { items: itemIds })

        const orders = data.map(item => {
          const order = new Order(item)
          /*
          const { products } = item

          products.forEach(element => {
            const orderItem = new OrderItem(
              element.id,
              element.images && !!element.images[0] ? element.images[0].URI : null,
              element.name,
              element.sku,
              element.barCode,
              element.pivot.note,
              element.pivot.quantity,
              element.pivot.price,
              element.pivot.discount,
              element.pivot.discount_type,
              element.pivot.vat,
            )

            order.addOrderItem(orderItem)
          })
          */

          return order
        })

        this.$store.commit('app/SET_PRINT_DATA', {
          type: $type,
          data: orders,
          company: company,
        })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    async printSingle($type, id) {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)

      try {
        const { company, data } = await asyncGet('/saleorder/' + $type, { items: [id] })

        this.$store.commit('app/SET_PRINT_DATA', {
          type: $type,
          data: data.map(item => {
            const order = new Order(item)
            /*
            const { products } = item

            products.forEach(element => {
              const orderItem = new OrderItem(
                element.id,
                element.images && !!element.images[0] ? element.images[0].URI : null,
                element.name,
                element.sku,
                element.barCode,
                element.pivot.note,
                element.pivot.quantity,
                element.pivot.price,
                element.pivot.discount,
                element.pivot.discount_type,
                element.pivot.vat,
              )

              order.addOrderItem(orderItem)
            })
            */

            return order
          }),
          company: company,
        })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    async togglePrint(id, status) {
      if (status) {
        const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
          title: 'ยกเลิก ?',
          body: 'คุณต้องการยกเลิกสถานะการพิมพ์ใช่หรือไม่ ?',
        })

        if (!dialogResult) return
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      try {
        await asyncPost('/saleorder/' + id + '/togglePrint', {})

        this.$refs.mainDatatable.reload()
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    async pack(id, status) {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      try {
        await asyncPost('/saleorder/' + id + '/pack', {})

        this.$refs.mainDatatable.reload()
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    async togglePack(id, status) {
      if (status) {
        const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
          title: 'ยกเลิก ?',
          body: 'คุณต้องการยกเลิกสถานะการแพ็คของใช่หรือไม่ ?',
        })

        if (!dialogResult) return
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      try {
        await asyncPost('/saleorder/' + id + '/togglePack', {})

        this.$refs.mainDatatable.reload()
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    async toggleDeliveryBooking(id, status) {
      if (status) {
        const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
          title: 'ยกเลิก ?',
          body: 'คุณต้องการยกเลิกการเรียกขนส่งใช่หรือไม่ ?',
        })

        if (!dialogResult) return
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      try {
        if (status) await asyncPost('/saleorder/' + id + '/cancelDeliveryBooking', {})
        else await asyncPost('/saleorder/' + id + '/deliveryBooking', {})

        this.$refs.mainDatatable.reload()
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    showLightBox(id) {
      this.$refs['lightbox_' + id].showImage(0)
    },
  },
  components: {
    ProductImage,
    OrderStatusBar,
    EditableTextArea,
    LightBox,
    cover,
  },
}
</script>
<style scoped>
.fixed-column-table::v-deep .v-data-table th.fixed-column {
  position: sticky;
  right: 0;
  background: white;
  z-index: 1;
}

.fixed-column-table tbody tr td.fixed-column {
  position: sticky;
  right: 0;
  background: white;
  z-index: 1;
}
</style>
