import axios from "@axios";

export const asyncPost = (url, payload, options = {}) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(payload)) {
        if (value === null) continue;

        formData.append(key, value);
    }

    return new Promise((resolve, reject) => {
        axios
        .post(url, formData, options)
        .then(function(response){
            let data = response.data;

            if (data.status && data.status == 99) reject(data.msg)

            resolve(data);
        })
        .catch(function(error) {
            reject(error.message)
        })
    })
}

export const asyncPostAsJson = (url, payload, options = {}) => {
    if (!!!options['headers']) {
        options['headers'] = {}
    }

    if (!!!options.headers['Content-Type']) {
        options.headers['Content-Type'] = 'application/json'
    }
    const jsonData = JSON.stringify(payload)
    return new Promise((resolve, reject) => {
        axios
        .post(url, jsonData, options)
        .then(function(response){
            let data = response.data;

            if (data.status && data.status == 99) reject(data.msg)

            resolve(data);
        })
        .catch(function(error) {
            reject(error.message)
        })
    })
}


export const asyncUpdate = (url, payload, options = {}) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(payload)) {

        if (value === null) continue;

        formData.append(key, value);
    }

    formData.append("_method", "PUT");

    return new Promise((resolve, reject) => {
        payload
        axios
        .post(url, formData, options)
        .then(function(response){
            let data = response.data;

            if (data.status && data.status == 99) reject(data.msg)

            resolve(data);
        })
        .catch(function(error) {
            reject(error.message)
        })
    })
}

export const asyncUpdateAsJson = (url, payload, options = {}) => {
    if (!!!options['headers']) {
        options['headers'] = {}
    }

    if (!!!options.headers['Content-Type']) {
        options.headers['Content-Type'] = 'application/json'
    }

    const jsonData = JSON.stringify({...payload, _method: 'PUT'})

    return new Promise((resolve, reject) => {
        axios
        .post(url, jsonData, options)
        .then(function(response){
            let data = response.data;

            if (data.status && data.status == 99) reject(data.msg)

            resolve(data);
        })
        .catch(function(error) {
            reject(error.message)
        })
    })
}

export const asyncGet = (url, payload, options = {}) => {
    return new Promise((resolve, reject) => {
        axios
        .get(url, { params: payload, ...options })
        .then(function(response){
            let data = response.data;

            if (data.status && data.status == 99) reject(data.msg)

            resolve(data);
        })
        .catch(function(error) {
            reject(error.message)
        })
    })
}

export const asyncGetFile = (url, payload, options = { responseType: 'blob' }) => {
  return new Promise((resolve, reject) => {
      axios
      .get(url, { params: payload, ...options })
      .then(function(response){
          resolve(response);
      })
      .catch(function(error) {
          reject(error.message)
      })
  })
}

export const asyncDelete = (url, payload) => {
    return new Promise((resolve, reject) => {
        axios
        .delete(url, { params: payload })
        .then(function(response){
            let data = response.data;

            if (data.status && data.status == 99) reject(data.msg)

            resolve(data);
        })
        .catch(function(error) {
            reject(error.message)
        })
    })
}


