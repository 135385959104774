<template>
    <v-form v-model="isFormValid">

        <v-row>
            <v-col>
                <v-select
                v-model="formData.courier_code"
                :items="courierList"
                label="ขนส่ง *"
                item-text="courier_name"
                item-value="courier_code"
                outlined
                dense
                :rules="[validators.required]"                
                hide-details="auto"
                ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                ข้อมูลผู้ส่ง<v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field 
                v-model="formData.address"                         
                label="ที่อยู่ *"
                outlined
                dense
                hide-details="auto"
                :rules="[validators.required, validators.maxLengthValidator(formData.address, 50)]"        
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <VThailandAddressAutoComplete 
                hide-details="auto" 
                dense v-model="formData.city" 
                placeholder="แขวง/ตำบล *" 
                name="city" 
                type="district" 
                @select="setAddress"
                :rules="[validators.required]"        
                />
            </v-col>
        </v-row>        
        <v-row>
            <v-col>
                <VThailandAddressAutoComplete 
                hide-details="auto" 
                dense 
                v-model="formData.county" 
                placeholder="เขต/อำเภอ *" 
                name="county" 
                type="amphoe" 
                @select="setAddress"
                :rules="[validators.required]"        
                />
            </v-col>
        </v-row>        
        <v-row>
            <v-col>
                <VThailandAddressAutoComplete 
                hide-details="auto" 
                dense 
                v-model="formData.province" 
                placeholder="จังหวัด *" 
                name="province" 
                type="province" 
                @select="setAddress"
                :rules="[validators.required]"
                />
            </v-col>
        </v-row>  
        <v-row>
            <v-col>
                <VThailandAddressAutoComplete 
                hide-details="auto" 
                dense 
                v-model="formData.postcode" 
                placeholder="เลขไปรษณีย์ *" 
                name="postcode" 
                type="zipcode" 
                @select="setAddress"
                :rules="[validators.required]"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field 
                v-model="formData.tel"                         
                label="เบอร์ *"
                outlined
                dense
                hide-details="auto"
                :rules="[validators.required, validators.maxLengthValidator(formData.tel, 20)]"            
                ></v-text-field>
            </v-col>
        </v-row>        
        
        <v-btn 
        class="primary mt-4" 
        :loading="isLoading"
        :disabled="!isFormValid"
        @click="submitData"
        >เรียกขนส่ง</v-btn>
    </v-form> 
</template>
<script>
import { asyncPost } from '@/helpers/asyncAxios'
import errorParser from '@/plugins/errorparser'
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
  requiredIf,
} from '@core/utils/validation'
export default {
  props: ['order', 'defaultAddress', 'courierList'],
  data() {
    return {
      isLoading: false,
      isFormValid: false,
      formData: {
        courier_code: null,
        address: !!this.defaultAddress.address ? this.defaultAddress.address : '',
        city: !!this.defaultAddress.city ? this.defaultAddress.city : '',
        county: !!this.defaultAddress.county ? this.defaultAddress.county : '',
        province: !!this.defaultAddress.province ? this.defaultAddress.province : '',
        postcode: !!this.defaultAddress.postcode ? this.defaultAddress.postcode : '',
        tel: this.defaultAddress.tel,
      },
      validators: {
        integerValidator,
        lengthValidator,
        maxLengthValidator,
        maxValueValidator,
        minLengthValidator,
        minValueValidator,
        numberValidator,
        required,
        requiredIf,
      },
    }
  },
  created() {},
  methods: {
    setAddress(address) {
      this.formData.city = address.district
      this.formData.county = address.amphoe
      this.formData.province = address.province
      this.formData.postcode = address.zipcode
    },
    async submitData() {
      this.isLoading = true

      try {
        await asyncPost('/saleorder/' + this.order.id + '/shippopBooking', this.formData)

        this.$emit('done')
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }

      this.isLoading = false
    },
  },
}
</script>