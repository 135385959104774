<template>
    <div>
        <h2>รายการรอส่ง</h2>
        <v-card class="mt-4 px-6 pt-6">
            <v-row>
              <v-col cols="12">
                <v-text-field
                outlined
                v-model="datatable.search"
                placeholder="ค้นหา ..."
                dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <delay-autocomplete
                  outlined
                  hide-selected
                  deletable-chips
                  multiple
                  chips
                  cache-items
                  v-model="datatable.salechannel_id"
                  label="ช่องทางการขาย"
                  placeholder="พิมพ์เพื่อค้นหาช่องทางการขาย"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/saleorder/salechannelList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="salechannelAutocomplete"
                  item-text="name"
                  item-value="id"
                ></delay-autocomplete>
              </v-col>
            </v-row>
        </v-card>
        <v-card class="mt-4">
          <div class="d-flex pa-4">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="datatable.selectedItems.length == 0"
                  icon
                ><v-icon>{{ mdiPrinter }}</v-icon></v-btn>
              </template>
              <v-list>

                <v-list-item
                  @click="printMultiple($PRINT_TYPE.PICK_LIST)"
                >
                  <v-list-item-title>ใบจัดของ</v-list-item-title>
                </v-list-item>

                <v-list-item
                  @click="printMultiple($PRINT_TYPE.COVER)"
                >
                  <v-list-item-title>ใบปะหน้า</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
            class="ml-2"
            @click="exportExcel"
            :disabled="datatable.selectedItems.length == 0"
            icon
            ><v-icon>{{ mdiFileExcel }}</v-icon></v-btn>
          </div>
          <delay-datatable
            show-select
            :headers="datatable.headers"
            :url="'/saleorder/pendingdelivery/list'"
            :queryParams="getDatatableQueryParams"
            :options.sync="datatable.options"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
            class="fixed-column-table"
            :mobile-breakpoint="0"
          >

          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox
              :value="props.value || props.indeterminate"
              v-on="on"
              :indeterminate="props.indeterminate"
              color="primary"
              :ripple="false"
            />
          </template>

          <template
            v-slot:item.created_at="{ item }"
          >
            {{ item.created_at | timestampToText }}
          </template>

          <template
            v-slot:item.creator="{ item }"
          >
            {{ item.creator ? item.creator.name + " " + item.creator.lastname : 'ระบบ' }}
          </template>

          <template
            v-slot:item.orderNumber="{ item }"
          >
            <router-link
            :to="{ name: 'pendingdelivery_create', params: { saleorder_id: item.id } }"
            >{{ item.orderNumber }}</router-link>
          </template>

          <template
            v-slot:item.salechannel="{ item }"
          >
            {{ !!item.salechannel ? item.salechannel.name : '-' }}
          </template>

          <template
            v-slot:item.value="{ item }"
          >
            {{ item.value | formatNumber(item.value, 2) }}
          </template>

          <template
            v-slot:item.customer="{ item }"
          >
            <div>{{ item.sns_name }}</div>
            <template v-if="!!item.customer">
              <div>{{ item.customer.name }}</div>
              <div>{{ item.customer.tel }}</div>
            </template>
          </template>

          <template
            v-slot:item.address="{ item }"
          >
          {{ item.address }} {{ item.county }} {{ item.city }} {{ item.province }} {{ item.postcode }}
          </template>

          <template
            v-slot:item.packing_status="{ item }"
          >
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                  :color="item.is_print ? 'success' : 'black'"
                  @click="togglePrint(item.id, item.is_print)"
                  v-bind="attrs"
                  v-on="on"
                  >{{ mdiPrinter }}</v-icon>
                </template>
                <span class="text-h6 font-weight-regular">{{ item.is_print ? 'พิมพ์ใบจัดของแล้ว' : 'ยังไม่ได้พิมพ์ใบจัดของ' }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                  :color="item.is_packed ? 'success' : 'black'"
                  class="ml-2"
                  @click="togglePack(item.id, item.is_packed)"
                  v-bind="attrs"
                  v-on="on"
                  >{{ mdiPackageVariantClosed }}</v-icon>
                </template>
                <span class="text-h6 font-weight-regular">{{ item.is_packed ? 'จัดของแล้ว' : 'ยังไม่ได้จัดของ' }}</span>
              </v-tooltip>
            </div>
          </template>

          <template
            v-slot:item.logistic="{ item }"
          >
            <v-chip-group>
              <v-chip
                v-if="!item.delivery_package"
                :color="'error'"
                label
              >
                ยังไม่ได้เรียกขนส่ง
              </v-chip>
              <v-chip
                v-else-if="item.delivery_package && item.delivery_package.delivery_method == 'MANUAL'"
                :color="'error'"
                label
              >
                ส่งเอง
              </v-chip>
              <v-chip
                v-else
                :color="'success'"
                label
              >
                เรียกขนส่งแล้ว
              </v-chip>
            </v-chip-group>
          </template>

          <template
            v-slot:item.label="{ item }"
          >
            <v-chip-group v-if="item.salechannel.channel_type == 'shopee'">
              <v-chip
                v-if="!item.delivery_package"
                :color="'error'"
                label
              >
                ยังไม่ได้เรียกขนส่ง
              </v-chip>
              <v-chip
                v-else-if="item.delivery_package.document_status == 'READY'"
                :color="'success'"
                label
              >
                พร้อมพิมพ์
              </v-chip>
              <v-chip
                v-else-if="item.delivery_package.document_status == 'FAILED'"
                :color="'orange'"
                label
              >
                ล้มเหลว
              </v-chip>
              <v-chip
                v-else-if="item.delivery_package.document_status == 'PROCESSING'"
                :color="'primary'"
                label
              >
                กำลังประมวลผล
              </v-chip>
            </v-chip-group>
            <v-chip-group v-else-if="item.delivery_package">
              <v-chip
                v-if="item.delivery_package.document_status == 'READY'"
                :color="'success'"
                label
              >
                พร้อมพิมพ์
              </v-chip>
              <v-chip
                v-else-if="item.delivery_package.document_status == 'FAILED'"
                :color="'error'"
                label
              >
                ล้มเหลว
              </v-chip>
              <v-chip
                v-else-if="item.delivery_package.document_status == 'PROCESSING'"
                :color="'primary'"
                label
              >
                กำลังประมวลผล
              </v-chip>
            </v-chip-group>
            <div v-else>-</div>
          </template>

          <template
            v-slot:item.actions="{ item }"
          >
            <td class="fixed-column">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                  >
                    <v-icon>{{ mdiMenu }}</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                  v-if="!item.is_packed""
                  @click="pack(item.id)"
                  >
                    <v-list-item-icon>
                      <v-icon>
                        {{ mdiPackageVariantClosed }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">จัดของแล้ว</v-list-item-title>
                  </v-list-item>

                  <v-divider v-if="!item.is_packed"></v-divider>

                  <v-list-item v-if="item.payment_type != 'cod' && !!item.paid_at && !!item.customer" @click="printSingle($PRINT_TYPE.ORDER, item.id)">
                    <v-list-item-icon>
                      <v-icon>{{mdiPrinter}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ใบสั่งของ</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="printSingle($PRINT_TYPE.PICK_LIST, item.id)">
                    <v-list-item-icon>
                      <v-icon>{{mdiPrinter}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ใบจัดของ</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="printSingle($PRINT_TYPE.COVER, item.id)" v-if="item.is_cod || !!item.paid_at">
                    <v-list-item-icon>
                      <v-icon>{{mdiPrinter}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ใบปะหน้า</v-list-item-title>
                  </v-list-item>

                  <template v-if="!!item.abbNumber && !!item.paid_at">
                    <v-list-item
                      v-if="!!item.trd"
                      @click="$router.push({ name: 'trd', query: { trdId: item.trd.id } })">
                      <v-list-item-icon>
                        <v-icon>{{mdiPrinter}}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="ml-2">ใบกำกับภาษีแบบเต็ม</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                    v-else
                    @click="$router.push({ name: 'trd_create', params: { trd_id: 'create' }, query: { order_id: item.id, orderNumber: item.orderNumber, order_type: 'saleorder' } })">
                      <v-list-item-icon>
                        <v-icon>{{mdiPrinter}}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="ml-2">ออกใบกำกับภาษีแบบเต็ม</v-list-item-title>
                    </v-list-item>
                  </template>

                  <v-divider></v-divider>

                  <v-list-item
                  v-if="!item.delivery_booked_at""
                  @click="callDeliveryOptionsDialog(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>
                        {{ mdiTruckDeliveryOutline }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">เรียกขนส่ง / จัดส่งแล้ว</v-list-item-title>
                  </v-list-item>


                </v-list>
              </v-menu>
            </td>
          </template>
          </delay-datatable>
        </v-card>
        <v-dialog
        v-model="deliveryDialog.isShow"
        max-width="600"
        persistent
        >
          <v-card
          v-if="deliveryDialog.order"
          class="pb-4"
          >
            <v-card-title>วิธีการจัดส่งของบิล #{{ deliveryDialog.order.orderNumber }}
              <v-btn
                icon
                @click="deliveryDialog.isShow = false"
                class="ml-auto"
              >
              <v-icon>{{mdiClose}}</v-icon>
              </v-btn>
            </v-card-title>
            <div
            class="px-4 error--text text-h6"
            v-if="!deliveryDialog.order.is_packed"
            >***บิลนี้ยังไม่ได้จัดของ</div>
            <div class="px-4">
              <DeliveryOptions
              :options="deliveryDialog.options"
              :order="deliveryDialog.order"
              @done="doneDelivery"
              ></DeliveryOptions>
            </div>
          </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { Order } from '@/classes/order/Order'
import cover from '@/components/print/sendcover.vue'
import ProductImage from '@/components/ProductImage.vue'
import { asyncGet, asyncGetFile, asyncPost } from '@/helpers/asyncAxios'
import { convertDocumentStatusToText } from '@/helpers/converter'
import {
  mdiCashCheck,
  mdiCheck,
  mdiClose,
  mdiCloudUpload,
  mdiContentCopy,
  mdiDelete,
  mdiEyeOutline,
  mdiFileExcel,
  mdiInformationOutline,
  mdiLinkVariant,
  mdiMenu,
  mdiPackageVariantClosed,
  mdiPlaylistEdit,
  mdiPrinter,
  mdiTruckDeliveryOutline,
} from '@mdi/js'
import LightBox from 'vue-image-lightbox'
import * as XLSX from 'xlsx'
import DeliveryOptions from './components/DeliveryOptions'
export default {
  data() {
    return {
      deliveryDialog: {
        isShow: false,
        order: null,
        options: null,
      },
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['created_at'],
          sortDesc: [true],
        },
        selectedStatus: 'all',
        selectedItems: [],
        salechannel_id: null,
        search: null,
        only_preorder: false,
        headers: [
          {
            text: 'เลขที่บิล',
            value: 'orderNumber',
          },
          {
            text: 'ช่องทางการขาย',
            value: 'salechannel',
            sortable: false,
            width: '200',
          },
          {
            text: 'ลูกค้า',
            value: 'customer',
            sortable: false,
            width: '200',
          },
          {
            text: 'ที่อยู่จัดส่ง',
            value: 'address',
            width: '200',
          },
          {
            text: 'มูลค่ารวม',
            value: 'value',
            width: '125',
          },
          {
            text: 'หมายเหตุ',
            value: 'note',
            width: '200',
          },
          {
            text: 'หมายเหตุลูกค้า',
            value: 'customer_note',
            width: '200',
          },
          {
            text: 'สถานะการจัดของ',
            value: 'packing_status',
            sortable: false,
            width: '150',
          },
          {
            text: 'สถานะขนส่ง',
            value: 'logistic',
            sortable: false,
            width: '150',
          },
          {
            text: 'สถานะใบปะหน้า',
            value: 'label',
            sortable: false,
            width: '150',
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'fixed-column',
          },
        ],
      },
      converters: {
        convertDocumentStatusToText,
      },
      mdiEyeOutline,
      mdiPlaylistEdit,
      mdiMenu,
      mdiLinkVariant,
      mdiCloudUpload,
      mdiInformationOutline,
      mdiTruckDeliveryOutline,
      mdiCheck,
      mdiDelete,
      mdiPrinter,
      mdiCashCheck,
      mdiPackageVariantClosed,
      mdiContentCopy,
      mdiFileExcel,
      mdiClose,
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        salechannel_id: this.datatable.salechannel_id,
        search: this.datatable.search,
      }
    },
  },
  created() {},
  methods: {
    isThirdPartyOrder(order) {
      if (order.salechannel.type == 'shopee') return true

      return false
    },
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    isArray(data) {
      return Array.isArray(data)
    },
    exportExcel() {
      const headers = this.datatable.headers.filter(item => item.value != 'actions').map(header => header.value)
      const headersText = this.datatable.headers.filter(item => item.value != 'actions').map(header => header.text)

      const data = this.datatable.selectedItems.map(item => {
        return headers.map(header => {
          if (!!item[header]) {
            if (header == 'created_at' || header == 'updated_at' || header == 'paid_at')
              return this.$options.filters.timestampToText(item[header])

            if (header == 'status') return this.$options.filters.orderStatusToText(item[header])

            if (header == 'creator' || header == 'updater') return item[header].name + ' ' + item[header].lastname

            if (header == 'customer') return item['sns_name'] + '\n\r' + item[header].name + '\n\r' + item[header].tel

            if (header == 'address')
              return item.address + ' ' + item.county + ' ' + item.city + ' ' + item.province + ' ' + item.postcode

            if (!!item[header].name) return item[header].name

            return item[header]
          }

          if (item[header] == 0) return 0

          return ''
        })
      })

      const arrayData = [headersText, ...data]

      const dataWS = XLSX.utils.aoa_to_sheet(arrayData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, 'export_saleorders.xlsx')
    },
    async printMultiple($type) {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)

      const itemIds = this.datatable.selectedItems.map(item => item.id)

      try {
        const { company, data } = await asyncGet('/saleorder/' + $type, { items: itemIds })

        const orders = data.map(item => {
          const order = new Order(item)

          return order
        })

        this.$store.commit('app/SET_PRINT_DATA', {
          type: $type,
          data: orders,
          company: company,
        })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    async printSingle($type, id) {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)

      try {
        const { company, data, shopee } = await asyncGet('/saleorder/' + $type, { items: [id] })

        this.$store.commit('app/SET_PRINT_DATA', {
          type: $type,
          data: data.map(item => {
            const order = new Order(item)

            return order
          }),
          company: company,
        })

        if (shopee) {
          groupedShopeeOrderIds = shopee.reduce((group, order) => {
            const { salechannel_id, id } = order
            if (!group[salechannel_id]) {
              group[salechannel_id] = []
            }
            group[salechannel_id].push(id)
            return group
          }, {})

          for (const [salechannel_id, ids] of Object.entries(groupedShopeeOrderIds)) {
            const response = await asyncGetFile('/saleorder/pendingdelivery/shopee/label', { items: [ids] })

            const blob = new Blob([response.data], { type: response.headers['content-type'] })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'shopee-label' // Default file name
            link.click()
          }
        }
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    showLightBox(id) {
      this.$refs['lightbox_' + id].showImage(0)
    },
    async callDeliveryOptionsDialog(order) {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      try {
        const options = await asyncGet('/saleorder/pendingdelivery/' + order.id + '/options')

        this.deliveryDialog.order = order
        this.deliveryDialog.options = options
        this.deliveryDialog.isShow = true
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    doneDelivery() {
      this.deliveryDialog.isShow = false
      this.$refs.mainDatatable.reload()
    },
    async pack(id) {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      try {
        await asyncPost('/saleorder/pendingdelivery/' + id + '/pack', {})

        this.$refs.mainDatatable.reload()
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    async togglePack(id, status) {
      if (status) {
        const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
          title: 'ยกเลิก ?',
          body: 'คุณต้องการยกเลิกสถานะการแพ็คของใช่หรือไม่ ?',
        })

        if (!dialogResult) return
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      try {
        await asyncPost('/saleorder/pendingdelivery/' + id + '/togglePack', {})

        this.$refs.mainDatatable.reload()
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    async togglePrint(id, status) {
      if (status) {
        const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
          title: 'ยกเลิก ?',
          body: 'คุณต้องการยกเลิกสถานะการพิมพ์ใช่หรือไม่ ?',
        })

        if (!dialogResult) return
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      try {
        await asyncPost('/saleorder/pendingdelivery/' + id + '/togglePrint', {})

        this.$refs.mainDatatable.reload()
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
  },
  components: {
    ProductImage,
    LightBox,
    cover,
    DeliveryOptions,
  },
}
</script>
<style scoped>
.fixed-column-table::v-deep .v-data-table th.fixed-column {
  position: sticky;
  right: 0;
  background: white;
  z-index: 1;
}

.fixed-column-table tbody tr td.fixed-column {
  position: sticky;
  right: 0;
  background: white;
  z-index: 1;
}
</style>
