<template>
    <v-form v-model="isFormValid">
        <v-row>
            <v-col>
                <v-select
                v-model="formData.address_id"
                :items="addressList"
                label="ที่อยู่ *"
                item-value="address_id"
                outlined
                dense
                :rules="[validators.required]"
                hide-details="auto"
                >
                  <template v-slot:item = "{ item }">
                    {{ item.address }} {{ item.district }} {{ item.town }} {{ item.city }} {{ item.state }} {{ item.zipcode }}
                  </template>

                  <template v-slot:selection = "{ item }">
                    {{ item.address }} {{ item.district }} {{ item.town }} {{ item.city }} {{ item.state }} {{ item.zipcode }}
                  </template>
                </v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-select
                v-model="formData.pickup_time_id"
                :items="timeSlotList"
                label="เวลา *"
                item-value="pickup_time_id"
                item-text="time_text"
                outlined
                dense
                :rules="[validators.required]"
                hide-details="auto"
                >
                </v-select>
            </v-col>
        </v-row>
        <v-row v-if="isRequiredTrackingNumber">
            <v-col>
                <v-text-field
                v-model="formData.tracking_number"
                label="เลขขนส่ง *"
                outlined
                dense
                hide-details="auto"
                :rules="[validators.required]"
                ></v-text-field>
            </v-col>
        </v-row>


        <v-btn
        class="primary mt-4"
        :loading="isLoading"
        :disabled="!isFormValid"
        @click="submitData"
        >เรียกขนส่ง</v-btn>
    </v-form>
</template>
<script>
import { asyncPost } from '@/helpers/asyncAxios'
import errorParser from '@/plugins/errorparser'
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
  requiredIf,
} from '@core/utils/validation'
export default {
  props: ['order', 'requiredFields', 'addressList'],
  data() {
    return {
      isLoading: false,
      isFormValid: false,
      formData: {
        address_id: null,
        pickup_time_id: null,
        tracking_number: null,
      },
      validators: {
        integerValidator,
        lengthValidator,
        maxLengthValidator,
        maxValueValidator,
        minLengthValidator,
        minValueValidator,
        numberValidator,
        required,
        requiredIf,
      },
    }
  },
  computed: {
    isRequiredPickupTimeId() {
      if (!this.requiredFields) return false

      return this.requiredFields.includes('pickup_time_id')
    },
    isRequiredTrackingNumber() {
      if (!this.requiredFields) return false

      return this.requiredFields.includes('tracking_number')
    },
    timeSlotList() {
      if (!this.formData.address_id) return []

      const index = this.addressList.findIndex(item => item.address_id == this.formData.address_id)

      if (index == -1) return []

      return this.addressList[index]['time_slot_list']
    },
  },
  methods: {
    async submitData() {
      this.isLoading = true

      try {
        const sendData = {
          address_id: this.formData.address_id,
        }

        if (this.isRequiredPickupTimeId) sendData['pickup_time_id'] = this.formData.pickup_time_id

        if (this.isRequiredTrackingNumber) sendData['tracking_number'] = this.formData.tracking_number

        await asyncPost('/saleorder/pendingdelivery/' + this.order.id + '/shopee/pickup', sendData)

        this.$emit('done')
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }

      this.isLoading = false
    },
  },
}
</script>
