<template>
    <div v-if="order">
        <h2><router-link :to="{ name: 'pendingdelivery' }">รายการรอส่ง</router-link> > รายการขาย {{ order.orderNumber }}</h2>
        <v-row class="mt-4">
          <v-col>
            <v-card>
              <v-form v-model="isFormValid">
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-checkbox
                        label="เป็นบิล COD"
                        v-model="order.is_cod"
                        :disabled="true"
                        ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <delay-autocomplete
                        outlined
                        hide-selected
                        cache-items
                        v-model="order.salechannel"
                        label="ช่องทางการขาย"
                        placeholder="พิมพ์เพื่อค้นหาช่องทางการขาย"
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/saleorder/salechannelList'"
                        dense
                        return-object
                        ref="salechannelAutocomplete"
                        item-text="name"
                        item-value="id"
                        :disabled="true"
                        hide-details="auto"                     
                      ></delay-autocomplete>
                    </v-col>
                    <v-col>
                      <delay-autocomplete
                        outlined
                        hide-selected
                        cache-items
                        v-model="order.warehouse"
                        label="คลัง"
                        placeholder="พิมพ์เพื่อค้นหาคลัง (เว้นว่างหากไม่เลือก)"
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/saleorder/warehouseList'"
                        dense
                        return-object
                        ref="warehouseAutocomplete"                        
                        item-text="name"
                        item-value="id"
                        :disabled="true"
                        :lazySearch="true"
                        hide-details="auto"
                      ></delay-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">                      
                      <v-text-field
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.sns_name"
                        label="ชื่อในโซเชียล"                                            
                        dense                        
                        :disabled="true"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-textarea
                        outlined
                        v-model="order.note"
                        label="หมายเหตุร้านค้า"
                        placeholder="หมายเหตุของร้านค้า *ลูกค้าไม่เห็นตรงนี้"
                        :disabled="true"
                      ></v-textarea>
                    </v-col>                    
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row>
                  <v-row>
                    <v-col>
                      <product-input
                      v-model="order"
                      :url="'saleorder'"
                      :warehouse_id="!!order.warehouse ? order.warehouse.id : null"
                      :disabled="true"
                      ></product-input>
                    </v-col>              
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-checkbox
                      label="เป็นสินค้า preorder"
                      v-model="order.is_preorder"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>

              <v-card-actions class="justify-end">
                <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.push({ name: 'pendingdelivery' })"
                >
                กลับ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
    </div>
</template>
<script>
import { Order } from '@/classes/order/Order'
import AooVue2Combobox from '@/components/AooVue2Combobox.vue'
import DelayCombobox from '@/components/DelayCombobox.vue'
import LightBox from '@/components/LightBox.vue'
import ProductInput from '@/components/order/ProductInput.vue'
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import { convertOrderStatusToText } from '@/helpers/converter'
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
  requiredIf,
} from '@core/utils/validation'
import { isString } from '@vueuse/shared'
export default {
  data() {
    return {
      orderNumber: null,
      isFormValid: false,
      sendingData: false,
      order: null,
      salechannel: null,
      warehouse: null,
      currentStatus: null,
      changeStatusDialog: {
        isShow: false,
        title: '',
        body: '',
      },
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
        requiredIf,
      },
    }
  },
  async created() {
    const saleorder_id = this.$route.params.saleorder_id

    if (saleorder_id == 'create' && !this.$route.query.duplicate) {
      // New order
      this.order = new Order({})
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    }
    if (saleorder_id == 'create' && !!this.$route.query.duplicate) {
      // Duplicate
      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      try {
        const response = await asyncGet('/saleorder/' + this.$route.query.duplicate)

        delete response.status
        delete response.orderNumber

        this.convertDataFromServerToForm(response)
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.$store.commit('app/SET_GLOBAL_LOADING', false)

      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'OnlineOrder'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/saleorder/' + saleorder_id)

      this.convertDataFromServerToForm(response)
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
    this.currentStatus = this.order.status
  },
  computed: {
    statusList() {
      if (this.order.status == 'cancelled')
        return [
          {
            text: 'ยกเลิก',
            value: 'cancelled',
          },
        ]

      if (this.order.status == 'new' || this.order.status == 'pending_info' || this.order.status == 'pending_confirm')
        return [
          {
            text: 'บิลใหม่',
            value: 'new',
          },
          {
            text: 'รอที่อยู่',
            value: 'pending_info',
          },
          {
            text: 'รอชำระเงิน',
            value: 'pending_confirm',
          },
          {
            text: 'ยกเลิก',
            value: 'cancelled',
          },
        ]

      if (this.order.status == 'pending_delivery' || this.order.status == 'dispatched')
        return [
          {
            text: 'รอจัดส่ง',
            value: 'pending_delivery',
          },
          {
            text: 'ส่งแล้ว',
            value: 'dispatched',
          },
          {
            text: 'ยกเลิก',
            value: 'cancelled',
          },
        ]

      return [
        {
          text: 'บิลใหม่',
          value: 'new',
        },
        {
          text: 'รอข้อมูลจัดส่ง',
          value: 'pending_info',
        },
        {
          text: 'รอชำระเงิน',
          value: 'pending_confirm',
        },
        {
          text: 'รอจัดส่ง',
          value: 'pending_delivery',
        },
        {
          text: 'ส่งแล้ว',
          value: 'dispatched',
        },
        {
          text: 'ยกเลิก',
          value: 'cancelled',
        },
      ]
    },
  },
  methods: {
    async submitForm(is_draft = false) {
      this.sendingData = true
      try {
        const sendData = {
          ...this.order.serialize(),
          is_draft: is_draft,
        }

        const saleorder_id = this.$route.params.saleorder_id
        if (saleorder_id == 'create') {
          const retResult = await asyncPostAsJson('/saleorder', sendData)

          this.$router.push({
            name: 'saleorder',
            query: {
              orderNumber: retResult.orderNumber,
              orderId: retResult.id,
              viewToken: retResult.viewToken,
            },
          })
        } else {
          sendData['_method'] = 'PUT'
          await asyncPostAsJson('/saleorder/' + saleorder_id, sendData)

          this.$router.push({ name: 'saleorder' })
        }
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
    convertDataFromServerToForm(orderDataFromServer) {
      this.order = new Order(orderDataFromServer)

      const { products } = orderDataFromServer

      // this.warehouse = warehouse

      /*
      const order = this.order

      products.forEach(element => {
        const orderItem = new OrderItem(
          element.id,
          element.images && !!element.images[0] ? element.images[0].URI : null,
          element.name,
          element.sku,
          element.barCode,
          element.pivot.note,
          element.pivot.quantity,
          element.pivot.price,
          element.pivot.discount,
          element.pivot.discount_type,
          element.pivot.vat,
        )

        order.addOrderItem(orderItem)
      })
      */
    },
    setAddress(address) {
      this.order.city = address.district
      this.order.county = address.amphoe
      this.order.province = address.province
      this.order.postcode = address.zipcode
    },
    filterCustomerByName(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.name && item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    },
    filterCustomerByTel(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.tel && item.tel.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    },
    filterCustomerBySNSName(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.name && item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    },
    selectCustomer(payload) {
      if (!payload || isString(payload)) return

      const { name, email, address, city, county, postcode, province, tel } = payload

      if (!name || !tel) return

      this.order.tel = tel
      this.order.name = name
      this.order.address = address
      this.order.email = email
      this.order.county = county
      this.order.city = city
      this.order.postcode = postcode
      this.order.province = province
    },
    selectCustomerBySNSName(payload) {
      if (!payload || isString(payload)) return

      const { name, email, address, city, county, postcode, province, tel } = payload.customer

      this.order.tel = tel
      this.order.name = name
      this.order.address = address
      this.order.email = email
      this.order.county = county
      this.order.city = city
      this.order.sns_name = payload.name
      this.order.postcode = postcode
      this.order.province = province
    },
    changeStatus(payload) {
      this.changeStatusDialog.title = 'เปลี่ยนสถานะเป็น "' + convertOrderStatusToText(payload) + '" ?'
      this.changeStatusDialog.body =
        'คุณต้องการเปลี่ยนสถานะจาก "' +
        convertOrderStatusToText(this.order.status) +
        '" เป็น "' +
        convertOrderStatusToText(payload) +
        '" หรือไม่ ?'
      this.changeStatusDialog.isShow = true
    },
    cancelChangeStatus() {
      this.changeStatusDialog.isShow = false
      this.changeStatusDialog.title = ''
      this.changeStatusDialog.body = ''
      this.currentStatus = this.order.status
    },
    async changeOrderStatus() {
      this.sendingData = true
      try {
        const sendData = {
          _method: 'PUT',
          toStatus: this.currentStatus,
        }
        const saleorder_id = this.$route.params.saleorder_id

        await asyncPostAsJson('/saleorder/' + saleorder_id + '/changeOrderStatus', sendData)

        this.$router.push({ name: 'saleorder' })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
  },
  components: {
    ProductInput,
    DelayCombobox,
    LightBox,
    AooVue2Combobox,
  },
}
</script>,