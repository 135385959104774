export default [
    {
        path: '/c/:company_id/saleorder',
        name: 'saleorder',
        component: () => import('@/views/order/saleorder/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'OnlineOrder',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/saleorder/:saleorder_id',
        name: 'saleorder_create',
        component: () => import('@/views/order/saleorder/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'OnlineOrder',
            action: 'update'
        },
    }, 
    {
        path: '/c/:company_id/saleorder/:saleorder_id/manual',
        name: 'saleorder_manual',
        component: () => import('@/views/order/saleorder/manual.vue'),
        meta: {
            layout: 'content',      
            resource: 'OnlineOrder',
            action: 'update'
        },
    }, 
    {
        path: '/c/:company_id/saleorder/:saleorder_id/confirm',
        name: 'saleorder_confirm',
        component: () => import('@/views/order/saleorder/confirm.vue'),
        meta: {
            layout: 'content',      
            resource: 'OnlineOrder',
            action: 'update'
        },
    },
    {
        path: '/c/:company_id/saleorder/:saleorder_id/info',
        name: 'saleorder_info',
        component: () => import('@/views/order/saleorder/info.vue'),
        meta: {
            layout: 'content',      
            resource: 'OnlineOrder',
            action: 'update'
        },
    },
    {
        path: '/c/:company_id/chat',
        name: 'chat',
        component: () => import('@/views/order/chat/index.vue'),
        meta: {
            layout: 'content-fullwidth',      
            resource: 'OnlineOrder',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/pendingdelivery',
        name: 'pendingdelivery',
        component: () => import('@/views/order/pendingdelivery/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'OnlineOrder',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/pendingdelivery/:saleorder_id',
        name: 'pendingdelivery_create',
        component: () => import('@/views/order/pendingdelivery/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'OnlineOrder',
            action: 'update'
        },
    }, 
]