<template>
    <v-textarea
        v-bind="$attrs"
        :class="['my-auto', { 'success': isUploadSuccess }]"
        :loading="isLoading"
        :hide-details="true"
        outlined
        dense
        @input="triggerUpdate($event)"
    ></v-textarea>
</template>
<script>
import { asyncUpdate } from '@/helpers/asyncAxios'
export default {
  props: {
    url: {
      type: String,
      require: true,
    },
    fieldName: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isUploadSuccess: false,
      updateDelayTimer: null,
    }
  },
  methods: {
    triggerUpdate(text) {
      if (!!this.updateDelayTimer) clearTimeout(this.updateDelayTimer)

      this.updateDelayTimer = setTimeout(this.updateData, 250, text)
    },
    async updateData(text) {
      this.isLoading = true

      const payload = {}

      payload[this.fieldName] = text

      try {
        await asyncUpdate(this.url, payload)
        this.isUploadSuccess = true
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.isLoading = false
    },
  },
}
</script>
