<template>
    <v-form v-model="isFormValid">

        <v-row v-if="isRequiredBranchId">
            <v-col>
                <v-select
                v-model="formData.branch_id"
                :items="branchList"
                label="สาขา *"
                item-value="branch_id"
                outlined
                dense
                :rules="[validators.required]"
                hide-details="auto"
                >
                    <template v-slot:item = "{ item }">
                        {{ item.address }} {{ item.district }} {{ item.town }} {{ item.city }} {{ item.zipcode }}
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <v-row v-if="isRequiredSenderRealName">
            <v-col>
                <v-text-field
                v-model="formData.sender_real_name"
                label="ชื่อผู้ส่ง *"
                outlined
                dense
                hide-details="auto"
                :rules="[validators.required]"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row v-if="isRequiredTrackingNumber">
            <v-col>
                <v-text-field
                v-model="formData.tracking_number"
                label="เลขขนส่ง *"
                outlined
                dense
                hide-details="auto"
                :rules="[validators.required]"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row v-if="isRequiredSlug">
            <v-col>
                <v-select
                v-model="formData.slug"
                :items="slugList"
                label="สาขา *"
                item-value="slug"
                item-text="slug_name"
                outlined
                dense
                :rules="[validators.required]"
                hide-details="auto"
                >
                </v-select>
            </v-col>
        </v-row>


        <v-btn
        class="primary mt-4"
        :loading="isLoading"
        :disabled="!isFormValid"
        @click="submitData"
        >เรียกขนส่ง</v-btn>
    </v-form>
</template>
<script>
import { asyncPost } from '@/helpers/asyncAxios'
import errorParser from '@/plugins/errorparser'
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
  requiredIf,
} from '@core/utils/validation'
export default {
  props: ['order', 'requiredFields', 'branchList', 'slugList'],
  data() {
    return {
      isLoading: false,
      isFormValid: false,
      formData: {
        branch_id: null,
        sender_real_name: null,
        tracking_number: null,
        slug: null,
      },
      validators: {
        integerValidator,
        lengthValidator,
        maxLengthValidator,
        maxValueValidator,
        minLengthValidator,
        minValueValidator,
        numberValidator,
        required,
        requiredIf,
      },
    }
  },
  created() {},
  computed: {
    isRequiredBranchId() {
      if (!this.requiredFields) return false

      return this.requiredFields.includes('branch_id')
    },
    isRequiredSenderRealName() {
      if (!this.requiredFields) return false

      return this.requiredFields.includes('sender_real_name')
    },
    isRequiredTrackingNumber() {
      if (!this.requiredFields) return false

      return this.requiredFields.includes('tracking_number')
    },
    isRequiredSlug() {
      if (!this.requiredFields) return false

      return this.requiredFields.includes('slug')
    },
  },
  methods: {
    async submitData() {
      this.isLoading = true

      try {
        const sendData = {}

        if (this.isRequiredBranchId) sendData['branch_id'] = this.formData.branch_id

        if (this.isRequiredSenderRealName) sendData['sender_real_name'] = this.formData.sender_real_name

        if (this.isRequiredTrackingNumber) sendData['tracking_number'] = this.formData.tracking_number

        if (this.isRequiredSlug) sendData['slug'] = this.formData.slug

        await asyncPost('/saleorder/pendingdelivery/' + this.order.id + '/shopee/dropoff', sendData)

        this.$emit('done')
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }

      this.isLoading = false
    },
  },
}
</script>
