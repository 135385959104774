import { canNavigate } from '@/plugins/acl/routeProtection'
import store from '@/store'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import VueRouter from 'vue-router'
import LoggedIn from './loggedIn'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/second-page',
    name: 'second-page',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('@/views/PrivacyPolicy.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/NotAuthorized.vue'),
    meta: {
      layout: 'blank'
    },
  },
  ...LoggedIn
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach(async (to, _, next) => {
  const access_token = VueCookies.get('access_token');
  const ws_token = VueCookies.get('ws_token');

  if (access_token)
  {
    store.commit('auth/LOGIN', { accessToken: access_token, wsToken: ws_token })
    store.commit('app/SET_MUTED_STATE', VueCookies.get('muted_state') == "true" ? true : false)
  }
  const isLoggedIn = store.getters['auth/isLogin']

  // Redirect if logged in

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/companies')
  }


  if (to.meta && !to.meta.resource) return next();

  // Redirect to login if not logged in
  if (to.meta && to.meta.resource && !isLoggedIn) return next({ name: 'auth-login' , query: { redirectTo: window.location.href }});

  if (isLoggedIn && !store.state.auth.user)
  {
    try {
      await store.dispatch('auth/FETCH_ME')
    } catch (error) {

      store.commit('auth/LOGOUT')

      return next({ name: 'auth-login' })
    }
  }

  if (to.name == 'companies') {
    store.commit('app/CLEAR_SELECTED_COMPANY');
  }

  if (isLoggedIn && to.params && to.params.company_id)
  {
    if (store.getters['app/GET_SELECTED_COMPANY_ID'] != to.params.company_id) {
      store.commit('app/SET_GLOBAL_LOADING', true);
      await store.dispatch('app/SWITCH_COMPANY_PROFILE', to.params.company_id)
      await store.dispatch('auth/FETCH_USER_ABILITY', to.params.company_id)
      store.dispatch('socket/INIT', { url: process.env.VUE_APP_EVENT, wsToken: store.state.auth.ws_token, companyId: to.params.company_id })
      store.commit('app/SET_GLOBAL_LOADING', false);
    }
  }
  else
  {
    await store.dispatch('auth/FETCH_USER_ABILITY')
  }

  const ability = store.state.auth.ability

  if (isLoggedIn && !store.getters['auth/isVerifiedEmail'] && to.name != 'verifycode')
  {
    return next({ name: 'verifycode' })
  }

  if (!canNavigate(to, ability)) {
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  return next()
})

export default router
