import { OrderItem } from '@/classes/order/OrderItem';
import { valueOrEmptyString } from '@/helpers/converter';
import { dateObjectToStandardAooFormat } from "@/helpers/dateTimeConverter";

export class Order
{
    orderItems = [];    
    discount = 0;
    discount_type = 'percent';
    delivery_cost = 0;    
    sns_name;
    name;
    address;
    city;
    county;
    province;
    postcode;
    tel;
    email;
    note;
    customer_note;
    trackingNo;    
    is_preorder = false;
    is_cod = false;

    #id;
    #orderNumber;
    #status;
    #viewToken;
    #abbNumber;
    #invNumber;
    #sns_ref;
    #paymentInfo;
    #payment_type;
    #payment_referral;
    #creditcard_no;
    #paid_at;
    #amount_transferred;
    #slipImg;
    #history;
    #updated_at;
    created_at;
    #shippopLabel;
    #creator;
    #vat;
    #trd;    
    
    #salechannel;
    warehouse;

    get isItemEditable() {
        if (this.isFromThirdParty) return false

        return this.#status == 'draft' || this.#status == 'new'
    }

    get isWarehouseChangeable() {
        return this.#status == 'draft'
    }

    get isSaleChannelChangeable() {
        if (this.isFromThirdParty) return false

        return this.#status == 'draft' || this.#status == 'new'
    }

    get isDeliveryInfoEditable() {
        if (this.isFromThirdParty) return false

        return this.#status != 'dispatched' && this.#status != 'cancelled'
    }

    get isCODEditable() {
        if (this.isFromThirdParty) return false

        return this.#status == 'draft' || this.#status == 'new'
    }

    get isSaveableAsDraft() {
        if (!this.id) return true

        if (this.status == 'draft') return true

        return false
    }

    get isCancellable() {
        if (this.status == 'cancelled') return false

        if (this.isFromThirdParty) return false

        return true
    }

    get isFromThirdParty() {
        if (!this.salechannel) return false

        if (this.salechannel.type == 'shopee') return true

        return false
    }

    get isMustMaskData() {
        if (!this.salechannel) return false
        
        if (this.salechannel.type == 'shopee') return true

        return false
    }

    get id() {
        return this.#id;
    }

    get orderNumber() {
        return this.#orderNumber;
    }
    
    /*
    get warehouse() {
        return this.#warehouse
    } 

    set warehouse(value) {
        this.#warehouse = value
    } 
    */   

    get warehouseId() {
        // return !!this.#warehouse ? this.#warehouse.id : null
        return !!this.warehouse ? this.warehouse.id : null
    }

    get warehouseName() {
        return !!this.warehouse ? this.warehouse.name : null
    }
    
    get salechannel() {
        return this.#salechannel
    }

    set salechannel(value) {
        const { warehouse } = value

        this.#salechannel = value

        if (!!warehouse) {            
            this.warehouse = warehouse
        }            
    }    

    get salechannelId() {        
        return !!this.#salechannel ? this.#salechannel.id : null
    }

    get link() {
        return process.env.VUE_APP_BILL_URL + '/' + this.#id + '/view/' + this.#viewToken
    }

    get priceBeforeDiscount() {
        return this.orderItems.reduce((previousValue, currentValue) => previousValue + currentValue.priceSum * 1, 0)
    }

    get discountInValue() {
        if (this.discount_type == 'percent') return (this.discount * this.priceBeforeDiscount / 100).toFixed(2);

        return this.discount;
    }

    get priceAfterDiscount() {
        return this.priceBeforeDiscount - this.discountInValue;
    }

    get VAT() {
        return this.#vat
    }

    get VATSum() {
        const beforeDiscount = this.orderItems.reduce((previousValue, currentValue) => previousValue + currentValue.VATInValue * 1, 0)

        if (this.discount_type == 'percent') return ((100 - this.discount) * beforeDiscount / 100).toFixed(2);

        const percent = this.discount / this.priceBeforeDiscount;

        return (beforeDiscount * (1 - percent)).toFixed(2);
    }

    get priceBeforeVAT() {        

        return this.totalPrice - this.VATSum
    }

    get totalPrice() {
        return this.priceAfterDiscount + this.delivery_cost * 1;
    }

    get status() {
        return this.#status
    }

    get abbNumber() {
        return this.#abbNumber
    }

    get invNumber() {
        return this.#invNumber
    }

    get snsRef() {
        return this.#sns_ref
    }

    get paymentInfo() {        
        return this.#paymentInfo
    }

    get paymentType() {
        return this.#payment_type
    }

    get paymentReferral() {
        return this.#payment_referral
    }

    get payment() {
        if (this.#payment_type == 'gb_credit') return 'ผ่านบัตรเครดิต (gbprimepay)';
            
        if (this.#payment_type == 'cod') return 'COD';        
        
        return 'โอนผ่าน ' + this.#paymentInfo.info.bank.name + ' บัญชี ' + this.#paymentInfo.name + ' ' + this.#paymentInfo.info.accountNo;
        
    }

    get creditCardNo() {
        return this.#creditcard_no
    }

    get paidTimestamp() {
        return this.#paid_at
    }

    get slipImg() {
        return this.#slipImg
    }

    get amountTransferred() {
        return this.#amount_transferred
    }

    get history() {
        return this.#history
    }

    get isPaid() {
        return !!this.#paid_at
    }

    get isCOD() {
        return this.paymentType == 'cod'
    }

    get shippopLabel() {
        return this.#shippopLabel
    }

    get isDispatched() {
        if (this.status == 'dispatched_cod') return true

        if (this.status == 'dispatched') return true
        
        return false
    }

    get updatedAt() {
        if (!this.#updated_at) return '-'

        return dateObjectToStandardAooFormat(new Date(this.#updated_at))
    }

    get createdAt() {
        if (!this.created_at) return '-'

        return dateObjectToStandardAooFormat(new Date(this.created_at))
    }

    get paidAt() {
        if (!this.#paid_at) return 'ยังไม่ชำระ'

        return dateObjectToStandardAooFormat(new Date(this.#paid_at))
    }

    get seller() {
        if (!this.#creator) return 'ขายโดยระบบ'

        return this.#creator.name + ' ' + this.#creator.lastname
    }

    get fullAddress() {
        return this.address + ' ' + this.city + ' ' + this.county + ' ' + this.province + ' ' + this.postcode
    }

    get trd() {
        return this.#trd
    }

    get branch() {
        return "สำนักงานใหญ่"
    }

    get quantityCount() {
        return this.orderItems.map(item => item.quantity).reduce((prevVal, curVal) => prevVal + curVal, 0)
    }

    constructor({ 
        id = null, 
        orderNumber = null,
        abbNumber = null,
        invNumber = null,
        sns_ref = null,
        sns_name = '',
        name = '',
        address = '',
        city = '',
        county = '',
        province = '',
        postcode = '',
        tel = '',
        email = '',
        note = '',
        status = 'draft', 
        customer_note = '', 
        discount = 0, 
        discount_type = 'percent',         
        delivery_cost = 0, 
        viewToken,
        trackingNo = '',
        payby = null,
        payment_type = null,
        payment_referral = null,
        creditcard_no = null,
        paid_at = null,
        amount_transferred = 0,
        slipImg = null,
        history = [],
        warehouse = null,
        salechannel = null,
        updated_at = null,
        created_at = null,
        shippopLabel = null,
        creator = null,
        vat = false,
        trd = null,
        products = [],
        is_preorder,
        is_cod
    }, autoAddItems = true)
    {
        this.#id = id;
        this.#orderNumber = orderNumber;
        this.#viewToken = viewToken
        this.#status = status;
        this.#abbNumber = abbNumber;
        this.#invNumber = invNumber;
        this.#sns_ref = sns_ref;
        this.#paymentInfo = payby;
        this.#payment_type = payment_type;
        this.#payment_referral = payment_referral;
        this.#creditcard_no = creditcard_no;
        this.#paid_at = paid_at;
        this.#amount_transferred = amount_transferred;
        this.#slipImg = slipImg;
        this.#history = history;
        this.#updated_at = updated_at
        this.created_at = created_at
        this.#shippopLabel = shippopLabel
        this.#creator = creator
        this.#vat = vat

        this.is_preorder = !!is_preorder
        this.is_cod = !!is_cod
        
        this.sns_name = valueOrEmptyString(sns_name);
        this.name = valueOrEmptyString(name);
        this.address = valueOrEmptyString(address);
        this.city = valueOrEmptyString(city);
        this.county = valueOrEmptyString(county);
        this.province = valueOrEmptyString(province);
        this.postcode = valueOrEmptyString(postcode);
        this.tel = valueOrEmptyString(tel);
        this.email = valueOrEmptyString(email);
        this.customer_note = customer_note;
        this.note = note;
        this.trackingNo = trackingNo;

        this.discount = !!discount ? discount : 0;
        this.discount_type = discount_type;
        
        this.delivery_cost = !!delivery_cost ? delivery_cost : 0;        

        
        this.warehouse = warehouse;
        this.#salechannel = salechannel;
        
       /*
        this.warehouse = warehouse;
        this.salechannel = salechannel;
        */

        if (autoAddItems)
        {
            products.forEach(product => {
                const { id, pivot, images } = product
                const { name, sku, barCode, note, quantity, price, discount, discount_type, vat, unit } = pivot
    
                const item = new OrderItem(
                    id, 
                    !!images && images.length > 0 ? images[0].URI : null, 
                    name, 
                    sku, 
                    barCode, 
                    note, 
                    quantity, 
                    price, 
                    discount, 
                    discount_type, 
                    vat, 
                    unit
                )
    
                this.addOrderItem(item)
            })
        }        
    }

    addOrderItem(orderItem) {
        if (!(orderItem instanceof OrderItem)) throw "can add only OrderItem Class"
        
        this.orderItems.push(orderItem)
    }    

    serialize() {
        return {
            items: this.orderItems.map(data => {
                return {
                  id: data.id,
                  quantity: data.quantity,
                  discount: data.discount,
                  discount_type: data.discount_type,
                  note: data.note,
                  price: data.price,
                }
            }),
            sns_name: this.sns_name,
            sns_ref: this.snsRef,
            name: this.name,
            address: this.address,
            city: this.city,
            county: this.county,
            province: this.province,
            postcode: this.postcode,
            tel: this.tel,
            email: this.email,
            note: this.note,
            customer_note: this.customer_note,            
            delivery_cost: !!this.delivery_cost ? this.delivery_cost : 0,
            discount: !!this.discount ? this.discount : 0,
            discount_type: this.discount_type,
            trackingNo: this.trackingNo,
            warehouse_id: this.warehouseId,
            salechannel_id: this.salechannelId,
            is_preorder: this.is_preorder,
            is_cod: this.is_cod
        }
    }
}